import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

const BasicDetailsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { exists } = queryString.parse(location.search);
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const onBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        onClick={onBackArrowClick}
        onKeyPress={() => {}}
        role="button"
        tabIndex="-1"
      >
        <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-6 h-6 mt-12 font-bold text-navy-500" />
      </div>
      <div className="p-4 mb-6 ml-5 text-navy-500">
        <p className="text-md">Drive</p>
        <h3 className="text-3xl font-bold ">Be the boss!</h3>
        <p className="text-md">Drive with NET and be a leader.</p>
      </div>

      {exists === 'false' && (
        <div className="p-4 mb-3">
          <label
            className="block w-full mb-2 text-sm font-bold text-black"
            htmlFor=""
          >
            Carrier Name
          </label>
          <input
            {...register('company')}
            className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
            type="text"
          />
          {errors.company && (
            <span className="text-xs text-red-700">
              {errors.company.message}
            </span>
          )}
        </div>
      )}

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Contact Name
        </label>
        <input
          {...register('name')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="text"
        />
        {errors.name && (
          <span className="text-xs text-red-700">{errors.name.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Email
        </label>
        <input
          {...register('email')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="email"
        />
        {errors.email && (
          <span className="text-xs text-red-700">{errors.email.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Create Password
        </label>
        <input
          {...register('password')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="password"
        />
        {errors.password && (
          <span className="text-xs text-red-700">
            {errors.password.message}
          </span>
        )}
      </div>
    </>
  );
};

export default BasicDetailsForm;

BasicDetailsForm.propTypes = {
  formContent: PropTypes.any.isRequired
};
