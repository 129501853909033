import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import { getCarrierExistsByDot } from '../../../services/carrier.service';

const CarrierSignupDotPage = () => {
  const navigate = useNavigate();
  const [dotNumber, setDotNumber] = useState('');
  const [errors, setErrors] = useState('');

  const fetchData = async () => {
    if (dotNumber.length) {
      try {
        const response = await getCarrierExistsByDot(dotNumber);

        if (!response?.data?.isAcceptable) {
          toast.error(
            'You cannot create an account as this carrier does not exist or is currently disabled in our system.'
          );
          return;
        }
        const exists = response?.data?.isCarrierExists;

        if (exists) {
          navigate(`/carrier-signup?dot=${dotNumber}&exists=${exists}`);
        } else {
          navigate(`/carrier-signup?dot=${dotNumber}&exists=${exists}`);
        }
      } catch (error) {
        setErrors(error?.response?.data.message ?? 'Something went wrong!');
        toast.error(error?.response?.data.message ?? 'Something went wrong!');
      }
    } else {
      setErrors('DOT Number is required');
    }
  };

  return (
    <div className="">
      <section className="py-10 lg:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto">
            <div className="mb-6 lg:mb-10 text-left">
              <div className="mb-6  p-4">
                <h3 className="text-2xl font-bold text-navy-500">DOT Number</h3>
              </div>
              <div className="mb-3  p-4">
                <label
                  className="w-full block text-black text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Enter your DOT Number
                </label>
                <input
                  className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border py-3 px-2"
                  type="text"
                  id="dotNumber"
                  onChange={(e) => {
                    setErrors('');
                    setDotNumber(e.target.value);
                  }}
                />
                {errors && (
                  <span className="text-md text-red-700">{errors}</span>
                )}
              </div>

              <div className="text-left p-4 relative">
                <button
                  type="submit"
                  className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                     bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 text-sm font-bold
                    transition duration-200 text-left"
                  onClick={fetchData}
                  disabled={errors.length}
                >
                  Submit
                  <svg
                    className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CarrierSignupDotPage;
