import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useAuthContext } from '../../contexts/auth.context';
import {
  UpdateDispatcherSettings,
  dispatcherProfileService
} from '../../services/dispatcher.service';
// import DeleteAccountButton from '../../components/DeleteAccountButton';

const CarrierSettings = () => {
  const [carrier, setCarrier] = useState();
  const [isAPICalled, setAPICalled] = useState(false);
  const { authUser } = useAuthContext();

  async function fetchCarrierProfile() {
    try {
      const response = await dispatcherProfileService();
      setCarrier(response?.data?.data);
      setAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchCarrierProfile();
  }, []);

  const saveSettings = async () => {
    try {
      const response = await UpdateDispatcherSettings(authUser?.user?.id, {
        shipmentBookNotify: carrier.shipmentBookNotify
      });
      if (response?.data) {
        toast.success('Settings saved successfully');
      } else {
        toast.error('Error in save settings');
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return (
    <section className="py-20">
      <div className="container mx-auto">
        <div className="mx-auto">
          <div className="mb-8 w-full lg:w-1/2 px-3">
            <div className="mb-6  p-4 relative pl-0">
              <h3 className="text-3xl font-bold text-navy-500">
                General Settings
              </h3>
            </div>

            <div className="mb-6">
              <h2 className="text-2xl font-bold pb-6">Notifications</h2>
              {isAPICalled ? (
                <div className="w-full pb-8 md:w-9/12">
                  <label className="block mb-2 tracking-wide">
                    <input
                      type="checkbox"
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      defaultChecked={carrier.shipmentBookNotify}
                      value={carrier.shipmentBookNotify}
                      onChange={(e) =>
                        setCarrier({
                          ...carrier,
                          shipmentBookNotify: e.target.checked
                        })
                      }
                    />
                    <span className="ml-1">Receive shipment book emails</span>
                  </label>
                </div>
              ) : null}
            </div>
            <div className="text-left w-full md:w-9/12 py-4">
              <button
                type="button"
                onClick={saveSettings}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                      hover:border-navy-600 active:border-navy-700 text-lg font-bold transition duration-200 text-left"
              >
                Save
                <span className="pointer-events-none float-right px-2 mr-2 text-xl">
                  &#10003;
                </span>
              </button>
            </div>
            {/* <DeleteAccountButton /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarrierSettings;
