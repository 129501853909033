import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { IoCloseSharp } from 'react-icons/io5';
import { SERVICE_TYPES } from '../../../constants/quote.constants';
import Roles from '../../../constants/roles.constants';
import { useAuthContext } from '../../../contexts/auth.context';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';
import { getAllCompaniesBySalesRep } from '../../../services/salesrep.service';
import { EquipmentTypes } from '../../../constants/carrier.constants';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../../constants/service_type.constants';
import CargoDefault from '../../../assets/images/cargo_default.png';
import { shipperProfileService } from '../../../services/shipper.service';
import ViewMode from '../../../components/QuickQuote/ViewMode';
import { getResponsibleUsersByCompany } from '../../../services/company.service';
import { isDuplicate, ROLE_TYPE } from '../../../constants/generalConstants';

const ServiceTypes = ({ nextClick, quoteData }) => {
  const { authUser } = useAuthContext();

  const [customers, setCustomers] = useState([]);
  const [responsibleUsers, setResponsibleUsers] = useState([]);
  const [selectedCargo, setSelectedCargo] = useState({});
  const [isSelectCargo, setSelectCargo] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useFormContext();

  const selectedServiceType = watch('service_type');

  const shipment = watch();
  const { cargos } = shipment;

  const currentCustomer = getValues('company');

  async function fetchDetails() {
    try {
      const response = await shipperProfileService(authUser.user.id);
      setUserDetails(response?.data);
    } catch (error) {
      toast.error(error?.message ?? 'Something went wrong!');
    }
  }

  async function fetchAllUsers() {
    try {
      const response = await getAllCompaniesBySalesRep();
      const activeCompanies = response?.data?.data.filter(
        (item) => item?.is_active === true
      );
      setCustomers(activeCompanies);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const fetchResponsibleUsers = async () => {
    try {
      const response = await getResponsibleUsersByCompany(currentCustomer?.id);

      let defaultValue;

      if (isDuplicate() && quoteData?.company?.id === currentCustomer?.id) {
        defaultValue = response?.data?.data.find(
          (item) => item?.id === quoteData?.responsibleUser
        );
      } else {
        defaultValue = response?.data?.data.find(
          (item) => item?.role?.id === ROLE_TYPE.MAIN
        );
      }
      setValue('responsible_user', defaultValue);
      setResponsibleUsers(response?.data?.data);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onNextClick = () => {
    if (
      getValues('isMultipleQuote') &&
      [LTL_FREIGHT, BLANKET_WRAP].includes(selectedServiceType)
    ) {
      setSelectCargo(true);
    } else {
      nextClick();
    }
  };

  const changeCargo = (cg) => {
    setSelectCargo(false);
    setSelectedCargo(cg);
    const pickupShipment = shipment?.pickupInfo?.find(
      (pick) => pick.pickup_id === cg.ship_from
    );
    const deliveryShipment = shipment?.deliveryInfo?.find(
      (del) => del.delivery_id === cg.ship_to
    );

    setValue('pickup_date', pickupShipment.pickup_date);
    setValue('pickup_time', pickupShipment.pickup_time);
    setValue('is_pickup_flexible', pickupShipment.is_pickup_flexible);
    setValue('is_pickup_time_flexible', pickupShipment.is_pickup_time_flexible);
    setValue(
      'pickup_formatted_address',
      pickupShipment.pickup_formatted_address
    );
    setValue('pickup_service_options', pickupShipment.pickup_service_options);
    setValue(
      'pickup_special_instructions',
      pickupShipment.pickup_special_instructions
    );
    setValue('pickup_close_time', pickupShipment.pickup_close_time);
    setValue('pickup_zip_code', pickupShipment.pickup_zip_code);
    setValue('pickup_from', pickupShipment.pickup_from);
    setValue('delivery_date', deliveryShipment.delivery_date);
    setValue('delivery_time', deliveryShipment.delivery_time);
    setValue('is_delivery_flexible', deliveryShipment.is_delivery_flexible);
    setValue(
      'is_delivery_time_flexible',
      deliveryShipment.is_delivery_time_flexible
    );
    setValue(
      'delivery_formatted_address',
      deliveryShipment.delivery_formatted_address
    );
    setValue(
      'delivery_service_options',
      deliveryShipment.delivery_service_options
    );
    setValue(
      'delivery_special_instructions',
      deliveryShipment.delivery_special_instructions
    );
    setValue('delivery_close_time', deliveryShipment.delivery_close_time);
    setValue('delivery_zip_code', deliveryShipment.delivery_zip_code);
    setValue('delivery_to', deliveryShipment.delivery_to);
    setValue('cargos', [cg]);
    setValue('isMultipleQuote', false);
    nextClick();
  };

  useEffect(() => {
    if (authUser.user.usertype === Roles.SHIPPER) {
      fetchDetails();
    } else if (
      [Roles.SALES_REP, Roles.CUSTOMER_REP].includes(authUser.user.usertype)
    ) {
      fetchAllUsers();
    }
  }, []);

  useEffect(() => {
    if (authUser.user.usertype !== Roles.SHIPPER && currentCustomer) {
      fetchResponsibleUsers();
    }
  }, [currentCustomer]);

  return (
    <>
      <div className="flex flex-row mb-2 justify-between px-4">
        <h3 className="text-3xl font-bold text-navy-500">New Quote</h3>
        <ViewMode />
      </div>

      {[Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
        authUser.user.usertype
      ) && (
        <div className="relative p-4 pb-5 mb-6 border border-t-0 border-l-0 border-r-0 border-gray-200">
          <p className="mb-2 text-xl text-left text-black">Select Customer</p>
          <div className="flex flex-col gap-4 mt-1 mb-3">
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={customers}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={(option) => option.id}
                  className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                />
              )}
            />
            {errors.company && (
              <span className="text-xs text-red-700">
                {errors.company.message}
              </span>
            )}
            {currentCustomer && (
              <div className="flex flex-col">
                <div className="flex flex-row justify-between m-4">
                  <div className="flex flex-col">
                    <span className="text-gray-600 ">Credit Limit:</span>
                    <span className="font-semibold">
                      {currentCustomer?.creditLimit.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600 ">Open balance:</span>
                    <span className="font-semibold">
                      {currentCustomer?.openBalance.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600">Terms:</span>
                    <span className="font-semibold">
                      {currentCustomer?.paymentTerms}
                    </span>
                  </div>
                </div>
                <span className="mb-2 text-lg text-left text-black">
                  Select Responsible User
                </span>
                <Controller
                  name="responsible_user"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={responsibleUsers}
                      getOptionLabel={({ name, role: { roleType } }) => (
                        <span className="">{`${name} (${roleType})`}</span>
                      )}
                      getOptionValue={(option) => option.id}
                      className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                    />
                  )}
                />
                {errors.responsible_user && (
                  <span className="text-xs text-red-700 mt-2">
                    {errors.responsible_user.message}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {authUser.user.usertype === Roles.SHIPPER && (
        <div className="flex flex-row gap-52 p-4 ml-1">
          <div className="flex flex-col">
            <span className="mb-2 text-gray-600 ">Credit Limit:</span>
            <span className="font-semibold">
              {userDetails?.companyId?.creditLimit.toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="mb-2 text-gray-600 ">Open balance:</span>
            <span className="font-semibold">
              {userDetails?.companyId?.openBalance.toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="mb-2 text-gray-600">Terms:</span>
            <span className="font-semibold">
              {userDetails?.companyId?.paymentTerms}
            </span>
          </div>
        </div>
      )}

      <div className="p-4 mb-4">
        {SERVICE_TYPES.map((serviceType) => {
          return (
            <div className="mb-4" key={serviceType.value}>
              <label>
                <input
                  {...register('service_type')}
                  type="radio"
                  value={serviceType.value}
                />
                <span className="ml-1">{serviceType.label}</span>
              </label>
            </div>
          );
        })}

        {errors.service_type && (
          <span className="text-xs text-red-700">
            {errors.service_type.message}
          </span>
        )}
      </div>

      {![LTL_FREIGHT, BLANKET_WRAP].includes(selectedServiceType) && (
        <div className="relative p-4 mb-6">
          <label className="block w-full mb-2 text-xl text-black" htmlFor="">
            Equipment Types
          </label>
          <Controller
            name="equipmentType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isMulti
                options={EquipmentTypes}
                className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
              />
            )}
          />
          {errors.equipmentType && (
            <span className="text-xs text-red-700">
              {errors.equipmentType.message}
            </span>
          )}
        </div>
      )}
      {isSelectCargo && (
        <div className="fixed inset-0 z-10 overflow-y-auto backdrop-filter backdrop-brightness-50">
          <div className="flex items-center min-h-screen px-2 py-4">
            <div className="relative w-full max-w-lg py-8 mx-auto bg-white rounded-md shadow-lg">
              <div className="flex justify-between mx-8 text-center">
                <p className="text-xl font-bold text-navy-500">
                  Please Select Cargo
                </p>
                <IoCloseSharp
                  className="cursor-pointer"
                  size={25}
                  onClick={() => setSelectCargo(false)}
                />
              </div>
              {cargos?.map((cg) => {
                return (
                  <div className="flex flex-col justify-between p-3 mx-8 my-6 bg-gray-100">
                    <label className="flex w-full">
                      <input
                        id="specialRadio"
                        type="radio"
                        value={cg?.id}
                        checked={cg?.id === selectedCargo?.id}
                        onChange={() => changeCargo(cg)}
                      />

                      <span className="flex gap-2">
                        <div className="inline-block">
                          {cg?.files?.fd ? (
                            <img
                              className="object-contain w-20 h-20"
                              src={`${process.env.REACT_APP_API_URL}/${cg?.files?.fd}`}
                              alt="cargo img"
                              onError={(e) => {
                                e.target.src = '';
                              }}
                            />
                          ) : (
                            <img
                              className="object-contain w-12 h-12"
                              src={CargoDefault}
                              alt="cargo img"
                              onError={(e) => {
                                e.target.src = CargoDefault;
                              }}
                            />
                          )}
                        </div>
                        <div className="ml-4">
                          <p>{cg?.quantity} - Box</p>
                          <p>
                            {cg?.length} in x {cg?.width} in x {cg?.height} in x{' '}
                            {cg?.weight} lbs
                          </p>
                        </div>
                      </span>
                    </label>
                    <div className="flex justify-between gap-4 mx-8 mt-4 text-sm">
                      <span className="w-1/2">
                        <p className="pt-2">
                          Goods Condition -{' '}
                          <span className="font-bold capitalize">
                            {cg?.goods_condition?.label}
                          </span>
                        </p>
                        <p className="pt-2">
                          Freight Class -{' '}
                          <span className="font-bold">
                            {cg?.freight_class?.label || 'N/A'}
                          </span>
                        </p>
                        <p className="pt-2">
                          Pickup Zip Code -{' '}
                          <span className="font-bold">
                            {
                              shipment?.pickupInfo?.find(
                                (pick) => pick.pickup_id === cg.ship_from
                              )?.pickup_zip_code
                            }
                          </span>
                        </p>
                      </span>
                      <span className="w-1/2">
                        <p className="pt-2">
                          Materials -{' '}
                          <span className="font-bold">
                            {cg?.goods_type?.map(
                              (type) => `${type?.display_name}, `
                            ) || 'N/A'}
                          </span>
                        </p>
                        <p className="pt-2">
                          NMFC Code -{' '}
                          <span className="font-bold">
                            {cg?.nmfc_code || 'N/A'}
                          </span>
                        </p>
                        <p className="pt-2">
                          Delivery Location -{' '}
                          <span className="font-bold">
                            {
                              shipment?.deliveryInfo?.find(
                                (del) => del.delivery_id === cg.ship_to
                              )?.delivery_zip_code
                            }
                          </span>
                        </p>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="relative p-4 text-left">
        <button
          type="button"
          onClick={onNextClick}
          className="order-1 inline-block w-full py-4 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
        >
          {/* {buttonLabels[step - 1]} */}
          Continue
          <RightArrowIcon className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-6 mr-4 font-bold pointer-events-none" />
        </button>
      </div>
    </>
  );
};

ServiceTypes.propTypes = {
  nextClick: PropTypes.any.isRequired,
  quoteData: PropTypes.object.isRequired
};

export default ServiceTypes;
