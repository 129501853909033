import React, { memo, useEffect, useState, useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { getCarrierByEmailService } from '../../services/carrier.service';
import { packetStatusMCP } from '../../constants/carrier.constants';
// eslint-disable-next-line import/no-cycle
import { carrierDetailContext } from '../../pages/SalesRep/CarrierDetailsPage';

const avatarColors = [
  'bg-blue-500',
  'bg-navy-200',
  'bg-red-600',
  'bg-navy-500',
  'bg-red-200',
  'bg-purple-300',
  'bg-gray-800'
];

const getColor = (name) => {
  const index = name ? name.charCodeAt(0) : 'Person' % avatarColors.length;
  return avatarColors[index] || 'bg-gray-500';
};
const CarrierEmails = memo(({ inputClass }) => {
  const { carrierData, setIsRegistered, selectedEmail, setSelectedEmail } =
    useContext(carrierDetailContext);
  const [isAddingEmail, setIsAddingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const handleAddNewEmail = () => {
    setIsAddingEmail(true);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (newEmail.trim() === '') return;

    setIsAddingEmail(false);
    setNewEmail('');
    setSelectedEmail(newEmail);
  };

  const checkCarrierDetails = async (email) => {
    try {
      const response = await getCarrierByEmailService({ email });
      if (response?.data?.data?.isExist) {
        setIsRegistered(true);
      } else {
        setIsRegistered(false);
      }
    } catch (error) {
      return toast.error(
        error?.response?.data.message ?? 'Something went wrong!'
      );
    }
  };

  useEffect(() => {
    if (selectedEmail) {
      checkCarrierDetails(selectedEmail);
    }
  }, [selectedEmail]);
  return (
    <div className="w-full">
      <Listbox value={selectedEmail} onChange={setSelectedEmail}>
        <div className="w-full max-w-md">
          {/* Dropdown Button */}
          <ListboxButton
            className={`w-2/3 p-3 flex text-sm text-gray-500 justify-between items-center bg-white overflow-auto ${inputClass}`}
          >
            {selectedEmail || 'Select Email'}
            <ChevronDownIcon className="w-5 h-5 text-gray-500" />
          </ListboxButton>

          {/* Dropdown List */}
          <ListboxOptions className="absolute w-1/3 h-auto mt-2 overflow-auto bg-white border border-gray-300 rounded-lg shadow-lg max-h-64">
            {carrierData?.carrierContacts?.map((data) => (
              <ListboxOption
                key={data.email}
                value={data.email}
                className={({ active }) =>
                  `cursor-pointer p-3 flex items-center justify-between border-t ${
                    active ? 'bg-gray-100' : 'bg-white'
                  }`
                }
              >
                {/* Left Section: Avatar + Name & Email */}
                <div className="flex items-center space-x-4">
                  {/* Avatar */}
                  <span
                    className={`w-8 h-8 flex items-center justify-center rounded-full text-white font-bold ${getColor(data.firstName)}`}
                  >
                    {data.firstName}
                  </span>

                  {/* Name & Email */}
                  <div className="flex flex-col leading-tight">
                    <span className="text-sm font-medium text-gray-900">
                      {`${data.firstName || ''} ${data.lastName || ''}`}
                    </span>
                    <span className="text-xs text-gray-600">{data.email}</span>
                  </div>
                </div>
                {/* Right Section: Identity Confirmed */}
                {packetStatusMCP
                  .filter((item) => item.value === data.identityStatus)
                  .map((item) => (
                    <span
                      key={item.value}
                      className={`flex items-center justify-center px-3 py-1 text-xs font-semibold rounded-full whitespace-nowrap ${item.bgcolor} ${item.textcolor}`}
                    >
                      {item.value}
                    </span>
                  ))}
              </ListboxOption>
            ))}

            {/* New Email Input Field */}
            {isAddingEmail && (
              <div className="flex items-center p-3 space-x-2 border-t">
                <input
                  type="email"
                  placeholder="Enter new email"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <button
                  className="font-semibold text-blue-600"
                  onClick={handleEmailSubmit}
                  type="button"
                >
                  Save
                </button>
              </div>
            )}

            {/* Add New Email Button */}
            {!isAddingEmail && (
              <div className="p-2 border-t">
                <button
                  className="flex items-center w-full p-2 text-blue-600 rounded-md hover:bg-gray-100"
                  type="button"
                  onClick={handleAddNewEmail}
                >
                  <PlusIcon className="w-5 h-5 mr-2" />
                  Add New Email
                </button>
              </div>
            )}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  );
});

export default CarrierEmails;

CarrierEmails.propTypes = {
  inputClass: PropTypes.string.isRequired
};
