import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const forgotPasswordSalesRep = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.ForgotPassword, body, {});
};

const resetPasswordSalesRep = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.ResetPassword, body, {});
};

const dashboardService = (month, duration) => {
  return API.get(API_ENDPOINTS.QUOTE.DashboardGraph, {
    params: {
      token: localStorage.getItem('token'),
      month,
      duration
    }
  });
};

const getAllCompaniesBySalesRep = () => {
  return API.get(API_ENDPOINTS.SALES_REP.GetAllCompaniesBySalesRep, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};
const getAllUsersBySalesRep = (limit = 0, skip = 0, query = '') => {
  return API.get(API_ENDPOINTS.SALES_REP.GetAllUsersBySalesRep, {
    params: {
      token: localStorage.getItem('token'),
      limit,
      skip,
      searchText: query
    }
  });
};

const getAllCarriers = (limit = 0, skip = 0, query = '') => {
  return API.get(API_ENDPOINTS.SALES_REP.GetCarriers, {
    params: {
      token: localStorage.getItem('token'),
      limit,
      skip,
      searchText: query
    }
  });
};

const shipperInvitationService = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.Invite, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const carrierInvitationService = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.CarrierInvite, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getAllSalesRepresentatives = () => {
  return API.get(API_ENDPOINTS.SALES_REP.GetAll, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateSettings = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.UpdateSettings, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const salesRepProfile = (userId) => {
  return API.get(`${API_ENDPOINTS.SALES_REP.Profile}/${userId}`, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};
const deleteUser = (userId) => {
  const myToken = localStorage.getItem('token');
  return API.del(
    `${API_ENDPOINTS.SALES_REP.DeleteUser}/${userId}?token=${myToken}`,
    {
      params: {
        platform: 'web'
      }
    }
  );
};

const requestManagerApproval = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.RequestManagerApproval, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

export {
  forgotPasswordSalesRep,
  resetPasswordSalesRep,
  dashboardService,
  getAllCompaniesBySalesRep,
  getAllUsersBySalesRep,
  getAllCarriers,
  shipperInvitationService,
  carrierInvitationService,
  getAllSalesRepresentatives,
  updateSettings,
  salesRepProfile,
  deleteUser,
  requestManagerApproval
};
