import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import {
  BLANKET_WRAP,
  LTL_FREIGHT,
  PICKUP_LIMITED_ACCESS
} from '../../../constants/service_type.constants';
import { PICKUP_LIMITED_ACCESS_TYPES } from '../../../constants/quote.constants';
import { isDuplicate } from '../../../constants/generalConstants';

const PickupServiceItem = ({ pickupServiceItem }) => {
  const {
    pickupServices,
    setPickupServices,
    serviceType,
    setServiceOptionsType,
    validate,
    isPickupLimitedAccessInvalid,
    serviceOptionsType
  } = useContext(GlobalFormContext);

  const [isChecked, setIsChecked] = useState(false);
  const [showLimitedAccessDropDown, setShowLimitedAccessDropDown] =
    useState(false);
  const [limitedAccessValue, setLimitedAccessValue] = useState(null);

  const removePickupLimitedAccess = () => {
    setServiceOptionsType((prevItems) => {
      const accessValues = PICKUP_LIMITED_ACCESS_TYPES.map(
        (item) => item.value
      );
      const updatedOptions = _.difference(prevItems, accessValues);
      return updatedOptions;
    });
    setShowLimitedAccessDropDown(false);
  };
  const handleOnChange = () => {
    if (!isChecked) {
      const updatedPickupServices = [
        ...pickupServices,
        pickupServiceItem.value
      ];
      setPickupServices(updatedPickupServices);
      setIsChecked(true);
    } else {
      const updatedPickupServices = pickupServices.filter(
        (item) => item !== pickupServiceItem.value
      );
      setPickupServices(updatedPickupServices);
      if (pickupServiceItem.value === PICKUP_LIMITED_ACCESS) {
        removePickupLimitedAccess();
      }
      setIsChecked(false);
    }
  };

  const handleSelect = (val, opt) => {
    setServiceOptionsType((prevItems) => {
      const accessValues = PICKUP_LIMITED_ACCESS_TYPES.map(
        (item) => item.value
      );
      const updatedOptions = _.difference(prevItems, accessValues);
      return [...updatedOptions, val];
    });
    setLimitedAccessValue(opt);
  };

  useEffect(() => {
    setIsChecked(pickupServices.includes(pickupServiceItem.value));
  }, [pickupServices]);

  useEffect(() => {
    if (![LTL_FREIGHT].includes(serviceType)) {
      const updatedPickupServices = pickupServices?.filter(
        (item) => !['67a310350ad93c6c5746381f'].includes(item)
      );
      setPickupServices(updatedPickupServices);
    }
  }, [serviceType]);

  useEffect(() => {
    if (
      [LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) &&
      pickupServices.includes(PICKUP_LIMITED_ACCESS)
    ) {
      setShowLimitedAccessDropDown(true);

      if (isDuplicate()) {
        const selectedLimitedAccess = _.intersectionWith(
          PICKUP_LIMITED_ACCESS_TYPES,
          serviceOptionsType,
          (obj, num) => obj.value === num
        )[0];
        setLimitedAccessValue(selectedLimitedAccess);
      }
    }
    if (
      ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) &&
      pickupServices.includes(PICKUP_LIMITED_ACCESS)
    ) {
      removePickupLimitedAccess();
    }

    if (
      [LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) &&
      !pickupServices.includes(PICKUP_LIMITED_ACCESS)
    ) {
      removePickupLimitedAccess();
    }
  }, [serviceType, pickupServices]);

  const pickUpOptionDisabled = () => {
    return (
      ['67a310350ad93c6c5746381f'].includes(pickupServiceItem.value) &&
      serviceType !== LTL_FREIGHT
    );
  };

  return (
    <div className="flex flex-row w-full items-center gap-2">
      <input
        className="service-options-checkbox"
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        disabled={pickUpOptionDisabled()}
      />
      <p>{pickupServiceItem?.label}</p>
      {pickupServiceItem.value === PICKUP_LIMITED_ACCESS &&
        showLimitedAccessDropDown && (
          <div className="flex flex-col ml-5">
            <Select
              value={limitedAccessValue}
              className="text-left "
              placeholder="Limited Access"
              style={{
                width: 150
              }}
              options={PICKUP_LIMITED_ACCESS_TYPES}
              onSelect={handleSelect}
            />
            {validate && isPickupLimitedAccessInvalid() && (
              <span className="text-sm text-red-700 ">
                Pickup limited access required
              </span>
            )}
          </div>
        )}
    </div>
  );
};

export default PickupServiceItem;

PickupServiceItem.propTypes = {
  pickupServiceItem: PropTypes.object.isRequired
};
