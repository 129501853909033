import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { carrierInvitationService } from '../../services/salesrep.service';
import { refreshMCPDataService } from '../../services/carrier.service';
// eslint-disable-next-line import/no-cycle
import { carrierDetailContext } from '../../pages/SalesRep/CarrierDetailsPage';

const CarrierDetailActions = () => {
  const {
    mcpData,
    setMCPData,
    selectedEmail,
    getValues,
    reset,
    registeredCarrierId,
    isRegistered,
    isCarrierSetupTeam
  } = useContext(carrierDetailContext);

  const onCarrierSendInvite = async () => {
    // TODO :: Only carrier setup team will be allowed to send invite on MCP
    // Others (i.e. agents) required to request for manager approval
    // TODO :: For others, Check if any previous invitation is pending
    // then send toast informing about previous request pending/in process
    try {
      const response = await carrierInvitationService({
        type: 'dot',
        number: mcpData.dot,
        usertype: 'carrier',
        email: selectedEmail || getValues('email')
      });

      return toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onRefreshData = async () => {
    try {
      const response = await refreshMCPDataService(registeredCarrierId);
      setMCPData(response?.data?.data?.mcpData);
      reset({
        ...response?.data?.data?.mcpData
      });
      toast.success('Data refreshed successfully!');
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <>
      {!isRegistered &&
        (['Acceptable', 'Moderate'].includes(mcpData?.riskAssesment) ||
          (mcpData?.riskAssesment === 'UnacceptableReview' &&
            isCarrierSetupTeam) ||
          mcpData?.carrierPacketStatus === 'Complete') &&
        !mcpData?.isBlocked && (
          <button
            type="button"
            className="px-4 py-2 mt-6 mr-4 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
            onClick={() => onCarrierSendInvite()}
          >
            Send Invite
          </button>
        )}
      {isRegistered && (
        <button
          type="button"
          className="px-4 py-2 mt-6 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
          onClick={() => onRefreshData()}
        >
          Refresh Data
        </button>
      )}
    </>
  );
};

export default CarrierDetailActions;
