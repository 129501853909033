import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import {
  BLANKET_WRAP,
  DELIVERY_LIMITED_ACCESS,
  DELIVERY_TRADE_SHOW,
  LTL_FREIGHT
} from '../../../constants/service_type.constants';
import {
  DELIVERY_LIMITED_ACCESS_TYPES,
  DELIVERY_TRADESHOW_TYPES
} from '../../../constants/quote.constants';
import { isDuplicate } from '../../../constants/generalConstants';

const DeliveryServiceItem = ({ deliveryServiceItem }) => {
  const {
    deliveryServices,
    setDeliveryServices,
    serviceType,
    serviceOptionsType,
    setServiceOptionsType,
    validate,
    isDeliveryLimitedAccessInvalid,
    isDeliveryTradeshowInvalid
  } = useContext(GlobalFormContext);

  const [isChecked, setIsChecked] = useState(false);
  const [showLimitedAccessDropDown, setShowLimitedAccessDropDown] =
    useState(false);
  const [showDeliveryTadeshow, setShowDeliveryTadeshow] = useState(false);
  const [limitedAccessValue, setLimitedAccessValue] = useState(null);
  const [tradeshow, setTradeshow] = useState(null);

  const removeDeliveryLimitedAccess = () => {
    setServiceOptionsType((prevItems) => {
      const accessValues = DELIVERY_LIMITED_ACCESS_TYPES.map(
        (item) => item.value
      );
      const updatedOptions = _.difference(prevItems, accessValues);
      return updatedOptions;
    });
    setShowLimitedAccessDropDown(false);
  };
  const removeDeliveryTradeshow = () => {
    setServiceOptionsType((prevItems) => {
      const tradeshowValues = DELIVERY_TRADESHOW_TYPES.map(
        (item) => item.value
      );
      const updatedOptions = _.difference(prevItems, tradeshowValues);
      return updatedOptions;
    });
    setShowDeliveryTadeshow(false);
  };

  const handleOnChange = () => {
    if (!isChecked) {
      const updatedDeliveryServices = [
        ...deliveryServices,
        deliveryServiceItem.value
      ];
      setDeliveryServices(updatedDeliveryServices);
      setIsChecked(true);
    } else {
      const updatedDeliveryServices = deliveryServices?.filter(
        (item) => item !== deliveryServiceItem.value
      );
      setDeliveryServices(updatedDeliveryServices);
      if (deliveryServiceItem.value === DELIVERY_LIMITED_ACCESS) {
        removeDeliveryLimitedAccess();
      }

      if (deliveryServiceItem.value === DELIVERY_TRADE_SHOW) {
        removeDeliveryTradeshow();
      }
      setIsChecked(false);
    }
  };

  const handleSelect = (val, obj) => {
    setLimitedAccessValue(obj);
    setServiceOptionsType((prevItems) => {
      const accessValues = DELIVERY_LIMITED_ACCESS_TYPES.map(
        (item) => item.value
      );
      const updatedOptions = _.difference(prevItems, accessValues);
      return [...updatedOptions, val];
    });
  };

  const handleTradeshowSelect = (val, obj) => {
    setTradeshow(obj);
    setServiceOptionsType((prevItems) => {
      const tradeshowValues = DELIVERY_TRADESHOW_TYPES.map(
        (item) => item.value
      );
      const updatedOptions = _.difference(prevItems, tradeshowValues);
      return [...updatedOptions, val];
    });
  };

  useEffect(() => {
    setIsChecked(deliveryServices?.includes(deliveryServiceItem.value));
  }, [deliveryServices]);

  useEffect(() => {
    if (![LTL_FREIGHT].includes(serviceType)) {
      const updatedDeliveryServices = deliveryServices?.filter(
        (item) =>
          ![
            '67a310350ad93c6c57463820',
            '67a310350ad93c6c57463821',
            '67a310350ad93c6c57463822',
            '67a310350ad93c6c57463823'
          ].includes(item)
      );
      setDeliveryServices(updatedDeliveryServices);
    }
  }, [serviceType]);

  useEffect(() => {
    if ([LTL_FREIGHT, BLANKET_WRAP].includes(serviceType)) {
      // setShowLimitedAccessDropDown(
      //   deliveryServices.includes(DELIVERY_LIMITED_ACCESS)
      // );
      if (deliveryServices.includes(DELIVERY_LIMITED_ACCESS)) {
        setShowLimitedAccessDropDown(true);
        if (isDuplicate()) {
          const selectedLimitedAccess = _.intersectionWith(
            DELIVERY_LIMITED_ACCESS_TYPES,
            serviceOptionsType,
            (obj, num) => obj.value === num
          )[0];
          setLimitedAccessValue(selectedLimitedAccess);
        }
      }
      if (deliveryServices.includes(DELIVERY_TRADE_SHOW)) {
        setShowDeliveryTadeshow(true);
        if (isDuplicate()) {
          const selectedtradeshow = _.intersectionWith(
            DELIVERY_TRADESHOW_TYPES,
            serviceOptionsType,
            (obj, num) => obj.value === num
          )[0];
          setTradeshow(selectedtradeshow);
        }
      }
      // setShowDeliveryTadeshow(deliveryServices.includes(DELIVERY_TRADE_SHOW));
    }
    if (serviceType && ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType)) {
      if (showLimitedAccessDropDown) {
        removeDeliveryLimitedAccess();
      }
      if (showDeliveryTadeshow) {
        removeDeliveryTradeshow();
      }
    }

    if ([LTL_FREIGHT, BLANKET_WRAP].includes(serviceType)) {
      if (!deliveryServices.includes(DELIVERY_LIMITED_ACCESS)) {
        removeDeliveryLimitedAccess();
      }
      if (!deliveryServices.includes(DELIVERY_TRADE_SHOW)) {
        removeDeliveryTradeshow();
      }
    }
  }, [serviceType, deliveryServices]);

  const deliveryOptionDisabled = () => {
    return (
      [
        '67a310350ad93c6c57463820',
        '67a310350ad93c6c57463821',
        '67a310350ad93c6c57463822',
        '67a310350ad93c6c57463823'
      ].includes(deliveryServiceItem.value) && serviceType !== LTL_FREIGHT
    );
  };

  return (
    <div className="flex flex-row w-full items-center gap-2">
      <input
        className="service-options-checkbox"
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        disabled={deliveryOptionDisabled()}
      />
      <p>{deliveryServiceItem?.label}</p>
      {deliveryServiceItem.value === DELIVERY_LIMITED_ACCESS &&
        showLimitedAccessDropDown && (
          <div className="flex flex-col ml-5">
            <Select
              value={limitedAccessValue}
              className="text-left"
              placeholder="Limited Access"
              style={{
                width: 150
              }}
              options={DELIVERY_LIMITED_ACCESS_TYPES}
              onSelect={handleSelect}
            />
            {validate && isDeliveryLimitedAccessInvalid() && (
              <span className="text-sm text-red-700">
                Delivery limited access required
              </span>
            )}
          </div>
        )}
      {deliveryServiceItem.value === DELIVERY_TRADE_SHOW &&
        showDeliveryTadeshow && (
          <div className="flex flex-col ml-5">
            <Select
              value={tradeshow}
              className="text-left ml-5"
              placeholder="Tradeshow Delivery"
              style={{
                width: 200
              }}
              options={DELIVERY_TRADESHOW_TYPES}
              onSelect={handleTradeshowSelect}
            />
            {validate && isDeliveryTradeshowInvalid() && (
              <span className="text-sm text-red-700">
                Delivery tradeshow required
              </span>
            )}
          </div>
        )}
    </div>
  );
};

export default DeliveryServiceItem;

DeliveryServiceItem.propTypes = {
  deliveryServiceItem: PropTypes.object.isRequired
};
