import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getCarrierMCPDataService } from '../../services/carrier.service';

const CarrierSearch = () => {
  const [DOTNumber, setDOTNumber] = useState();
  const [MCNumber, setMCNumber] = useState();
  const navigate = useNavigate();

  const onCarrierImportData = async (type) => {
    try {
      if (!DOTNumber && !MCNumber)
        return toast.error('Please enter DOT or MC number to search carrier');
      const mcNumberReplaced = !MCNumber?.includes('MC')
        ? `MC${MCNumber}`
        : MCNumber;

      const response = await getCarrierMCPDataService({
        type,
        number: type === 'dot' ? DOTNumber : mcNumberReplaced
      });

      if (type === 'dot') {
        setDOTNumber('');
      } else {
        setMCNumber('');
      }

      const mcpData = response?.data?.data;
      if (!mcpData) return toast.error('No data found.');

      let tempType = 'dot';
      let tempData = null;
      if (mcpData?.mcpData?.dot) {
        tempData = mcpData?.mcpData?.dot;
      } else if (mcpData?.mcpData?.mc) {
        tempData = mcpData?.mcpData?.mc;
        tempType = 'mc';
      } else {
        return toast.error('No data found.');
      }
      navigate(`/salesRep/carriers/${tempType}/${tempData}`, {
        state: mcpData
      });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return (
    <>
      <div>
        <label className="text-sm ">Enter DOT number</label>
        <input
          type="text"
          className="block w-full px-4 py-2 my-2 bg-white border rounded-md focus:border-navy-500 focus:ring-navy-500 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Enter DOT number"
          onChange={(e) => setDOTNumber(e.target.value)}
          value={DOTNumber}
        />

        <button
          className="px-4 py-2 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
          type="button"
          onClick={() => onCarrierImportData('dot')}
        >
          Search
        </button>
      </div>
      <p>OR</p>
      <div>
        <label className="text-sm ">Enter MC number</label>
        <input
          type="text"
          className="block w-full px-4 py-2 my-2 bg-white border rounded-md focus:border-navy-500 focus:ring-navy-500 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Enter MC number"
          onChange={(e) => setMCNumber(e.target.value)}
          value={MCNumber}
        />
        <button
          className="px-4 py-2 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
          type="button"
          onClick={() => onCarrierImportData('mc')}
        >
          Search
        </button>
      </div>
    </>
  );
};

export default CarrierSearch;
