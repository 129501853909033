import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import QuoteIcon from '../QuoteIcon';
import { BID_TYPE } from '../../constants/bids.constants';
import ChevronRightIcon from '../ChevronRightIcon';

const CarrierCard = memo(({ quote, summaryPath, showEnroute, loadType }) => {
  const { pathname, state } = summaryPath;
  const { FIXED_AMOUNT, OPEN_BID } = BID_TYPE;

  return (
    <div className="mb-6 mt-5 lg:mb-0 w-full lg:w-full px-3">
      <Link to={pathname} state={state}>
        <div className="p-6 flex flex-wrap bg-gray-100 border border-gray-100 shadow ">
          <div>
            <QuoteIcon />
          </div>
          <div className="w-full lg:w-2/3">
            <h3 className="mb-2 text-xl font-bold font-heading">
              {loadType === 'carrier-assigned-loads' ||
              loadType === 'carrier-enroute-loads' ||
              loadType === 'carrier-completed-loads'
                ? quote.company
                : ''}
            </h3>
            <p className="text-black ">
              From:{' '}
              <span className="font-bold">
                {quote?.isMultipleQuote
                  ? quote?.pickupInfo?.length &&
                    quote?.pickupInfo
                      ?.map((pick) => pick.pickup_zip_code)
                      ?.join(', ')
                  : quote.pickup_zip_code}
              </span>{' '}
              &nbsp; To:{' '}
              <span className="font-bold">
                {quote?.isMultipleQuote
                  ? quote?.deliveryInfo?.length &&
                    quote?.deliveryInfo
                      ?.map((del) => del.delivery_zip_code)
                      ?.join(', ')
                  : quote.delivery_zip_code}
              </span>
            </p>
            <p className="text-black ">
              Service Type:{' '}
              <span className="font-bold">{quote.serviceTypeName}</span>
            </p>
            {(loadType === 'carrier-assigned-loads' ||
              loadType === 'carrier-enroute-loads' ||
              loadType === 'carrier-completed-loads') && (
              <p className="text-black ">
                Dispatcher:{' '}
                <span className="font-bold">
                  {quote.dispatcherEmail} ({quote.dispatcherName})
                </span>
              </p>
            )}

            {quote?.bidType === OPEN_BID ? (
              <h3 className="mb-2 text-xl font-bold font-heading text-green-700">
                Open Bid
              </h3>
            ) : (
              quote.bidType === FIXED_AMOUNT && (
                <h3 className="mb-2 text-xl font-bold font-heading">
                  Budget: ${Number(quote.amount).toFixed(2)}
                </h3>
              )
            )}
            {!(
              quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID
            ) && (
              <h3 className="mb-2 text-xl font-bold font-heading">
                Budget: ${Number(quote.amount).toFixed(2)}
              </h3>
            )}
            {showEnroute &&
              (quote.isPickupByCarrier ? (
                <h3 className="text-green-700">
                  This shipment has been picked up at {quote.carrierPickupDate}{' '}
                  {quote.carrierPickUpTime}.
                </h3>
              ) : (
                <h3 className="text-red-600">
                  This shipment is not picked up yet.
                </h3>
              ))}
          </div>
          <div className="mt-11 pl-8">
            {quote?.internalTrackingNumber && (
              <span> #{quote.internalTrackingNumber}</span>
            )}
          </div>
          <div className="mt-auto ml-auto mb-auto">
            <ChevronRightIcon />
          </div>
        </div>
      </Link>
    </div>
  );
});

export default CarrierCard;

CarrierCard.propTypes = {
  quote: PropTypes.object.isRequired,
  summaryPath: PropTypes.any.isRequired,
  showEnroute: PropTypes.bool.isRequired,
  loadType: PropTypes.string.isRequired
};
