import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const dispatcherProfileService = () => {
  return API.get(
    `${API_ENDPOINTS.DISPATCHER.Profile}?token=${localStorage.getItem('token')}`
  );
};

const UpdateDispatcherSettings = (userId, body) => {
  return API.put(
    `${API_ENDPOINTS.DISPATCHER.UpdateDispatcherSettings}/${userId}`,
    body,
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const UpdateDispatcherStatus = (id, body) => {
  return API.put(
    `${API_ENDPOINTS.DISPATCHER.UpdateDispatcherStatus}/${id}`,
    body,
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

export {
  UpdateDispatcherSettings,
  dispatcherProfileService,
  UpdateDispatcherStatus
};
