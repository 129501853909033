import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import QuoteDetailsCard from '../../components/QuoteDetailsCard/QuoteDetailsCard';
import { availableLoadsService } from '../../services/carrier.service';
import { filterQuotesService } from '../../services/quote.service';
import { ReactComponent as FiltersIcon } from '../../assets/images/filters.svg';
import CarrierFilters from '../../components/CarrierFilters/CarrierFilters';

const AvailableLoadsPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [isAPICalled, setisAPICalled] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [filters, setfilters] = useState({});

  const [showModal, setshowModal] = useState(false);

  const toggleModal = () => setshowModal((prevModal) => !prevModal);

  async function fetchAvailableLoads() {
    try {
      const response = await availableLoadsService();
      if (response.data.flag) {
        setQuotes(response.data.bid);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchFiltersData() {
    try {
      setisAPICalled(false);

      const response = await filterQuotesService(filters);

      setQuotes(response.data.quotes);
      setisAPICalled(true);
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const onApplyFilters = (filtersData) => {
    try {
      toggleModal();
      setfilters(filtersData);
      // fetchFiltersData();
      const query = queryString.stringify(filtersData, {
        arrayFormat: 'bracket'
      });

      navigate(`/available-loads?${query}`, { replace: true });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onClearAllFilters = () => {
    setfilters({});
    navigate('/available-loads', { replace: true });
    fetchAvailableLoads();
  };

  useEffect(() => {
    if (search.length) {
      const filtersObj = queryString.parse(search, { arrayFormat: 'bracket' });
      setfilters(filtersObj);
    } else {
      const link = localStorage.getItem('link');
      if (link) {
        localStorage.removeItem('link');
        return navigate(link);
      }
      fetchAvailableLoads();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchFiltersData();
    }
  }, [filters]);

  return (
    <>
      {/* {authUser.user.isVerifiedOnStripe ? ( */}
      <section>
        <div className="py-20 w-full block bg-gray-50 radius-for-skewed">
          <div className="container mx-auto px-4">
            <div className="mb-6  py-4 relative">
              <div className="w-full md:w-6/12 inline-block">
                <h3 className="text-3xl font-bold inline-block text-navy-500">
                  Available Loads
                </h3>
              </div>
              <div className="w-full md:w-6/12 inline-block text-right items-center">
                {Object.keys(filters).length > 0 && (
                  <button
                    type="button"
                    className="mr-4 underline "
                    onClick={onClearAllFilters}
                  >
                    <span className="text-red-600 font-bold">Clear All</span>
                  </button>
                )}

                <button
                  type="button"
                  onClick={toggleModal}
                  className="relative inline-block pl-10 mb-2 py-3 pr-4 bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 text-base font-bold text-left"
                >
                  <FiltersIcon className="w-12 h-12 pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 mr-4 font-bold" />
                  Filters
                </button>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              {isAPICalled && quotes?.length
                ? quotes.map((quote) => (
                    <QuoteDetailsCard
                      key={quote.id}
                      quote={quote}
                      loadType="carrier-available-loads"
                    />
                  ))
                : isAPICalled && 'No bids to display'}
            </div>
          </div>
        </div>

        {showModal && (
          <CarrierFilters
            selectedFilters={filters}
            closeModal={toggleModal}
            onApply={onApplyFilters}
          />
        )}
      </section>
      {/* ) : ( */}
      {/* <Redirect to="/carrier-onboarding" /> */}
      {/* )} */}
    </>
  );
};

export default AvailableLoadsPage;
