import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const availableLoadsService = () => {
  return API.get(API_ENDPOINTS.CARRIER.ListQuotes, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([1])
    }
  });
};

const carrierSignUpService = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.SignUp, body, null);
};

const carrierProfileService = () => {
  return API.post(
    API_ENDPOINTS.CARRIER.Profile,
    {},
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const carrierStripeOnBoardService = () => {
  return API.post(
    API_ENDPOINTS.CARRIER.StripeOnBoard,
    {},
    {
      params: {
        token: localStorage.getItem('token'),
        platform: 'web'
      }
    }
  );
};

const carrierAcceptBidService = (quoteid, amount) => {
  return API.post(
    API_ENDPOINTS.CARRIER.AcceptBid,
    { quoteid, amount },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const carrierRejectBidService = (quoteId) => {
  return API.post(
    API_ENDPOINTS.CARRIER.RejectBid,
    { quoteId },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const carrierMarkAsDelivered = (quoteId) => {
  return API.post(
    API_ENDPOINTS.CARRIER.MarkAsDelivered,
    { quoteId },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const carrierMarkAsPicked = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.MarkAsPicked, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const carrierRebidService = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.Rebid, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateDOTNumberService = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.UpdateDOTNumber, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateCarrierService = (userId, body) => {
  return API.put(`${API_ENDPOINTS.CARRIER.Update}/${userId}`, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getCarriersService = () => {
  return API.get(API_ENDPOINTS.CARRIER.Carries, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getCarrierMCPDataService = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.GetMCPData, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const refreshMCPDataService = (carrierId) => {
  return API.get(`${API_ENDPOINTS.CARRIER.RefreshMCPData}/${carrierId}`, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};
const getDashboardLoads = (limit, skip, body, isFiltered) => {
  return API.post(API_ENDPOINTS.CARRIER.DashboardBids, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web',
      limit,
      skip,
      isFiltered
    }
  });
};

const getCarrierDetailsService = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.CarrierDetails, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getCarrierByEmailService = (body) => {
  return API.post(API_ENDPOINTS.CARRIER.CarrierByEmail, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getDispatchers = (carrierId) => {
  return API.get(`${API_ENDPOINTS.CARRIER.GetDispatchers}/${carrierId}`, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getCarrierExistsByDot = (dot) => {
  return API.get(`${API_ENDPOINTS.CARRIER.GetCarrierExistsByDot}/${dot}`);
};

const acceptedLoadsService = () => {};

export {
  availableLoadsService,
  acceptedLoadsService,
  carrierSignUpService,
  carrierProfileService,
  carrierStripeOnBoardService,
  carrierAcceptBidService,
  carrierRejectBidService,
  carrierRebidService,
  carrierMarkAsDelivered,
  carrierMarkAsPicked,
  updateDOTNumberService,
  updateCarrierService,
  getCarriersService,
  getCarrierMCPDataService,
  refreshMCPDataService,
  getDashboardLoads,
  getCarrierDetailsService,
  getCarrierByEmailService,
  getDispatchers,
  getCarrierExistsByDot
};
