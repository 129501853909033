import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';
import { IoLocationSharp } from 'react-icons/io5';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import moment from 'moment';
import Roles from '../../../constants/roles.constants';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import TimePickerInput from '../../../components/TimePickerInput/TimePickerInput';
import { PICKUP_SERVICE_OPTION } from '../../../constants/quote.constants';
import {
  LTL_FREIGHT,
  PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT,
  PICKUP_RESIDENTIAL,
  PICKUP_INSIDE_PICKUP,
  PICKUP_LIMITED_ACCESS
} from '../../../constants/service_type.constants';
import { loadAddressService } from '../../../services/quote.service';
import { useAuthContext } from '../../../contexts/auth.context';
import {
  getAddressFromLatLong
  // standByGeoService,
} from '../../../services/geo.service';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';
import { formatPickupDeliveryTime } from '../../../utils/date_conversion.util';
import { addLocationService } from '../../../services/shipper.service';
import { pickupValidationSchema } from '../../../utils/quote.util';

const AddPickup = ({
  addPickup,
  updatePickup,
  isAddMode,
  initialValues,
  serviceType,
  nextClick,
  showPickupForm,
  setShowPickupForm,
  changedPickups,
  setChangedPickups
}) => {
  const validationSchema = pickupValidationSchema;

  const [isDateDisable, setDateDisable] = useState(false);
  const [isTimeDisable, setTimeDisable] = useState(false);
  const [pickupMessage, setpickupMessage] = useState(false);
  const [address, setAddress] = useState('');
  const [userAddress, setUserAddress] = useState([]);
  const [IsChangeAdd, setIsChangeAdd] = useState(false);
  const [IsFromList, setIsFromList] = useState(false);

  const { authUser } = useAuthContext();

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { getValues: getContextVal } = useFormContext({});

  const pickupInfo = watch();
  const isPickupFlexible = watch('is_pickup_flexible');

  const isPickupTimeFlexible = watch('is_pickup_time_flexible');

  const pickUpServices = watch('pickup_service_options');

  const handleSelect = async (value, placeId) => {
    try {
      let id = placeId;
      if (!id) {
        const result = await geocodeByAddress(value);
        id = result[0].place_id;
      }
      const [place] = await geocodeByPlaceId(id.trim());

      const { addr, postcode, city, state, country } =
        await getAddressFromLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          value,
          place.postcode_localities,
          place
        );

      setAddress(value);
      setValue(
        'pickup_from',
        `${addr} | ${city}, ${state}, ${postcode}, ${country}`
      );
      setValue('pickup_formatted_address', `${value} | ${placeId}`);
      setValue('pickup_zip_code', postcode);
      setValue('pickup_state', state);
    } catch (error) {
      console.log(error);
    }
  };

  async function loadAddresses() {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getContextVal('company')?.id;
      const response = await loadAddressService(companyId, 'pickup');

      setUserAddress(response.data.data);
      const defaultAddress = response.data.data?.filter?.(
        (addr) => addr.isDefault
      )?.[0];
      const pickupAdd = getContextVal('pickupInfo')?.length;

      if (!pickupAdd)
        handleSelect(defaultAddress?.name, defaultAddress?.locationId);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    if (IsChangeAdd) {
      setChangedPickups([...changedPickups, pickupInfo.pickup_id]);
    }
  }, [IsChangeAdd]);

  useEffect(() => {
    if (isAddMode) {
      reset({
        is_pickup_flexible: true,
        is_pickup_time_flexible: true
      });
    } else {
      reset(initialValues);
    }

    loadAddresses();
    setAddress(getValues('pickup_formatted_address')?.split('|')?.[0]);
  }, [isAddMode, initialValues]);

  useEffect(() => {
    const addrData = userAddress?.find(
      (pick) => pick?.pickup_formatted_address?.trim() === address
    );

    if (addrData) {
      setValue(
        'pickup_time',
        addrData?.pickup_time !== 'flexible time'
          ? new Date(moment(addrData.pickup_time, 'h:mm a').format())
          : ''
      );
      setValue(
        'pickup_close_time',
        addrData?.pickup_close_time
          ? new Date(moment(addrData.pickup_close_time, 'h:mm a').format())
          : ''
      );
      setValue('is_pickup_time_flexible', addrData?.is_pickup_time_flexible);
      setValue('pickup_service_options', addrData?.pickup_service_options);
      setValue(
        'pickup_special_instructions',
        addrData?.pickup_special_instructions
      );
    }

    if (userAddress?.length && addrData) {
      setIsFromList(true);
      setValue('pickupLocation', addrData?.id);
    } else {
      setIsFromList(false);
      setValue('pickupLocation', null);
    }
  }, [address]);

  useEffect(() => {
    if (isPickupFlexible) {
      setValue('pickup_date', '', { shouldValidate: true });
      setDateDisable(true);
    } else {
      setDateDisable(false);
    }
  }, [isPickupFlexible]);

  useEffect(() => {
    if (isPickupTimeFlexible) {
      setValue('pickup_time', '', { shouldValidate: true });
      setValue('pickup_close_time', '', { shouldValidate: true });
      setTimeDisable(true);
    } else {
      setTimeDisable(false);
    }
  }, [isPickupTimeFlexible]);

  useEffect(() => {
    if (typeof pickUpServices === 'string' && pickUpServices.length) {
      setValue('pickup_service_options', [pickUpServices]);
    }
    if (pickUpServices && pickUpServices.length) {
      if (
        serviceType === LTL_FREIGHT &&
        pickUpServices.includes(PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        pickUpServices.includes(PICKUP_RESIDENTIAL)
      ) {
        setValue(
          'pickup_service_options',
          pickUpServices.filter(
            (opt) =>
              opt !== PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT &&
              opt !== PICKUP_RESIDENTIAL
          ),
          {
            shouldValidate: true
          }
        );
        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
      if (!pickUpServices.includes(PICKUP_LIMITED_ACCESS)) {
        setValue('delivery_limited_access_type', undefined);
      }
      if (pickUpServices.includes(PICKUP_INSIDE_PICKUP) && !pickupMessage) {
        toast.success(
          'Please fill up special instructions for us at the bottom of this page, since you have selected inside pick up.'
        );
        setpickupMessage(true);
      }
    }
  }, [pickUpServices]);

  const onAddPickup = async () => {
    if (!showPickupForm) {
      setShowPickupForm(true);
      return;
    }
    await trigger([
      'pickup_zip_code',
      'pickup_from',
      'pickup_formatted_address',
      'pickup_date',
      'is_pickup_flexible',
      'pickup_time',
      'pickup_close_time',
      'is_pickup_time_flexible',
      'pickup_service_options',
      'pickup_special_instructions',
      'pickup_limited_access_type',
      'pickup_id',
      'pickupLocation',
      'pickup_state'
    ]);

    if (!Object.keys(errors).length) {
      const newData = {
        pickup_zip_code: pickupInfo.pickup_zip_code,
        pickup_from: pickupInfo.pickup_from,
        pickup_formatted_address: pickupInfo.pickup_formatted_address,
        pickup_date: pickupInfo.pickup_date || '',
        is_pickup_flexible: pickupInfo.is_pickup_flexible,
        pickup_time: pickupInfo.pickup_time || '',
        pickup_close_time: pickupInfo.pickup_close_time || '',
        is_pickup_time_flexible: pickupInfo.is_pickup_time_flexible,
        pickup_service_options: pickupInfo.pickup_service_options,
        pickup_special_instructions: pickupInfo.pickup_special_instructions,
        pickup_limited_access_type:
          pickupInfo.pickup_zippickup_limited_access_type_code,
        pickup_id: pickupInfo.pickup_id || uuidv4(),
        pickupLocation: pickupInfo?.pickupLocation,
        pickup_state: pickupInfo.pickup_state,
        ...(pickupInfo.pickup_address &&
          !IsChangeAdd && {
            pickup_address: pickupInfo.pickup_address
          })
      };

      if (pickupInfo.id) newData.id = pickupInfo.id;
      if (isAddMode) {
        addPickup(newData);
      } else {
        updatePickup(newData);
      }

      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
      setShowPickupForm(false);
      setIsChangeAdd(false);
      return true;
    }
    return false;
  };

  const saveNewAddress = async () => {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getContextVal('company')?.id;
      const pickupData = {
        locationId: getValues('pickup_formatted_address')
          ?.split('|')?.[1]
          ?.trim(),
        name: getValues('pickup_formatted_address')?.split('|')?.[0]?.trim(),
        pickup_formatted_address: getValues('pickup_formatted_address')
          ?.split('|')?.[0]
          ?.trim(),
        pickup_from: getValues('pickup_from'),
        pickup_zip_code: getValues('pickup_zip_code'),
        pickup_state: getValues('pickup_state'),
        type: 'pickup',
        companyId,
        pickup_time: formatPickupDeliveryTime(
          getValues('pickup_time'),
          getValues('is_pickup_time_flexible')
        ),
        pickup_close_time: !getValues('pickup_close_time')
          ? ''
          : formatPickupDeliveryTime(getValues('pickup_close_time'), false),
        pickup_service_options: getValues('pickup_service_options'),
        service_options_type: [getValues('pickup_limited_access_type')]
          ?.filter((type) => type)
          .map((option) => option.value),
        is_pickup_time_flexible: getValues('is_pickup_time_flexible'),
        pickup_special_instructions: getValues('pickup_special_instructions')
      };

      validationSchema
        .validate(pickupData, { abortEarly: false })
        .then(async () => {
          const response = await addLocationService(pickupData);
          toast.success('Pickup address saved successfully!');
          setUserAddress([...userAddress, response?.data?.data]);
          setIsFromList(true);
        })
        .catch((error) => {
          console.log(error);
          return toast.error('Invalid data');
        });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const suggestionsDivRef = useRef(null);

  const nextButtonClick = async () => {
    await onAddPickup();
    if (getContextVal('pickupInfo')?.length) {
      nextClick();
    }
  };

  const onAddressChange = (e) => {
    suggestionsDivRef.current.scrollTop = 0;
    setIsChangeAdd(true);
    setAddress(e);
  };

  const pickUpOptionDisabled = (pickupOption) => {
    return (
      ['67a310350ad93c6c5746381f'].includes(pickupOption.value) &&
      serviceType !== LTL_FREIGHT
    );
  };

  useEffect(() => {
    if (![LTL_FREIGHT].includes(serviceType)) {
      const updatedPickupServices = pickUpServices?.filter(
        (item) => !['67a310350ad93c6c5746381f'].includes(item)
      );
      setValue('pickup_service_options', updatedPickupServices);
    }
  }, []);

  return (
    <div>
      {showPickupForm && (
        <div>
          <div className="mb-3  p-4">
            <label
              className="w-full block text-black text-sm font-bold mb-2"
              htmlFor="ship-from"
            >
              Ship From
            </label>
            <PlacesAutocomplete
              value={address ?? ''}
              onChange={(e) => onAddressChange(e)}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <div className="flex gap-3 items-center">
                    <input
                      className="leading-tight shadow appearance-none w-full text-xs bg-gray-100 border-gray-200 border-solid border py-3 px-2"
                      {...getInputProps({ placeholder: 'Type address' })}
                    />
                    {!IsChangeAdd && (
                      <div className="inline-flex justify-end w-1/5">
                        <button
                          type="button"
                          onClick={() => setIsChangeAdd(true)}
                          className="flex py-2 px-2 gap-1 items-center h-10 whitespace-normal max-w-max border rounded-sm border-navy-500 hover:bg-navy-600 hover:text-white active:bg-navy-700 text-navy-500 
    hover:border-navy-600 active:border-navy-700 text-sm break-words"
                        >
                          <IoLocationSharp size={20} />
                          Change Address
                        </button>
                      </div>
                    )}
                  </div>

                  <div
                    className="overflow-scroll max-h-48"
                    ref={suggestionsDivRef}
                  >
                    {IsChangeAdd && (
                      <div className="mt-2 space-y-3 bg-gray-100 px-2 py-2 border-gray-200 border-solid border shadow-lg">
                        <div className="bg-gray-100 mt-3">
                          {suggestions.length > 0 && (
                            <>
                              <div className="flex flex-col gap-2">
                                <p className="text-gray-400">Other Address</p>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  return (
                                    <label
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion)}
                                    >
                                      <input
                                        {...register('selectedAddress')}
                                        type="radio"
                                        value={suggestion.id}
                                      />

                                      <span className="">
                                        {suggestion.description}
                                      </span>
                                    </label>
                                  );
                                })}
                              </div>
                              <hr className="mt-3" />
                            </>
                          )}
                        </div>
                        <p className="text-gray-400">Default Address</p>
                        <div className="flex flex-col mb-4 mt-4">
                          {userAddress
                            ?.filter((addr) => addr.isDefault)
                            ?.map((addr) => {
                              return (
                                <label>
                                  <input
                                    {...register('selectedAddress')}
                                    type="radio"
                                    value={addr.id}
                                    checked={addr.name === address}
                                    onChange={() =>
                                      handleSelect(addr.name, addr.locationId)
                                    }
                                  />
                                  <span>{addr.name}</span>
                                </label>
                              );
                            })}
                        </div>
                        <hr />
                        <div>
                          <p className="text-gray-400">Saved Address</p>
                          <div className="mb-4 mt-4 flex flex-col gap-2 ">
                            {userAddress
                              ?.filter((addr) => !addr.isDefault)
                              ?.map((addr) => {
                                return (
                                  <label key={addr?.id}>
                                    <input
                                      {...register('selectedAddress')}
                                      type="radio"
                                      value={addr.id}
                                      onChange={() =>
                                        handleSelect(addr.name, addr.locationId)
                                      }
                                      checked={addr.name === address}
                                    />
                                    <span className="">{addr.name}</span>
                                  </label>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {errors?.pickup_zip_code && (
              <span className="text-xs text-red-700">
                {errors?.pickup_zip_code.message}
              </span>
            )}
          </div>
          <hr className="mx-3" />
          <div className="flex">
            <div className="flex-col w-full">
              <div className="pb-0 p-4">
                <label
                  className="w-full block text-black text-sm font-bold mb-2"
                  htmlFor="pickup-date"
                >
                  Pickup Date
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <DatePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value ?? ''}
                      isDisabled={isDateDisable ?? false}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="pickup_date"
                  control={control}
                />
                {errors.pickup_date && (
                  <span className="text-xs text-red-700">
                    {errors.pickup_date.message}
                  </span>
                )}
              </div>
              <div className="mb-3 p-4">
                <label>
                  <input
                    {...register('is_pickup_flexible')}
                    type="checkbox"
                    defaultChecked={
                      !isPickupFlexible && !getValues('pickup_date')
                    }
                  />
                  <span className="ml-1 text-sm">
                    Flexible (within next week)
                  </span>
                </label>
                {errors.is_pickup_flexible && (
                  <span className="text-xs text-red-700">
                    {errors.is_pickup_flexible.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex-col w-full">
              <div className="pb-0 p-4">
                <label
                  className="w-full block text-black text-sm font-bold mb-2"
                  htmlFor="pickup-time"
                >
                  Pickup Time
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <TimePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value ?? ''}
                      isDisabled={isTimeDisable ?? false}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="pickup_time"
                  control={control}
                />
                {errors.pickup_time && (
                  <span className="text-xs text-red-700">
                    {errors.pickup_time.message}
                  </span>
                )}
              </div>

              <div className="mb-3 p-4">
                <label>
                  <input
                    {...register('is_pickup_time_flexible')}
                    type="checkbox"
                    defaultChecked={
                      !isPickupTimeFlexible && !getValues('pickup_time')
                    }
                  />
                  <span className="ml-1 text-sm">Flexible (8am-5pm)</span>
                </label>
                {errors.is_pickup_time_flexible && (
                  <span className="text-xs text-red-700">
                    {errors.is_pickup_time_flexible.message}
                  </span>
                )}
              </div>
            </div>
            <div className="mb-3 pb-0 p-4 w-full">
              <label
                className="w-full block text-black text-sm font-bold mb-2"
                htmlFor="pickup-time"
              >
                Pickup Close Time
              </label>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value ?? ''}
                    classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    isDisabled={isTimeDisable}
                  />
                )}
                name="pickup_close_time"
                control={control}
              />
              {errors.pickup_close_time && (
                <span className="text-xs text-red-700">
                  {errors.pickup_close_time.message}
                </span>
              )}
            </div>
          </div>
          <hr className="mx-3" />

          <div className=" p-4">
            <label
              className="w-full block text-black text-sm font-bold mb-2"
              htmlFor="pickup-date"
            >
              Select Pickup Services
            </label>
            <div className="grid grid-cols-4 mt-6">
              {PICKUP_SERVICE_OPTION.map((pickupOption) => {
                return (
                  <div className="mb-4" key={pickupOption.value}>
                    <label
                      className={
                        pickUpOptionDisabled(pickupOption)
                          ? `opacity-50 cursor-not-allowed`
                          : `default`
                      }
                    >
                      <input
                        {...register('pickup_service_options')}
                        type="checkbox"
                        value={pickupOption.value}
                        defaultChecked={
                          isAddMode &&
                          pickupOption.value === '57fb6370b13762ee6a3f94d0'
                        }
                        disabled={pickUpOptionDisabled(pickupOption)}
                      />
                      <span className="ml-1">{pickupOption.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            {errors.pickup_service_options && (
              <span className="text-xs text-red-700">
                {errors.pickup_service_options.message}
              </span>
            )}
            {errors?.pickup_limited_access_type && (
              <span className="text-xs text-red-700">
                {errors?.pickup_limited_access_type?.message}
              </span>
            )}
          </div>
          <hr className="mx-3" />

          <div className="mb-3  p-4">
            <label
              className="w-full block text-black text-sm font-bold mb-2"
              htmlFor="pickup-date"
            >
              Special pickup instructions
            </label>
            <textarea
              {...register('pickup_special_instructions')}
              className="leading-tight shadow appearance-none w-full bg-gray-100 border-gray-200 border-solid border py-2 pb-10 px-2"
              placeholder="Write your instructions here"
            />
          </div>
          {errors.pickup_special_instructions && (
            <span className="text-xs text-red-700">
              {errors.pickup_special_instructions.message}
            </span>
          )}
          {!IsFromList && (
            <div className="p-4 mt-10">
              <button
                type="button"
                onClick={() => saveNewAddress()}
                className="bg-navy-500 text-white px-4 py-2 text-sm font-semibold"
              >
                Save Pickup Address
              </button>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col xl:flex-row w-full">
        <div className="text-left p-4 relative xl:w-1/2">
          <button
            type="button"
            onClick={onAddPickup}
            className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                      hover:border-navy-600 active:border-navy-700  text-sm font-bold transition duration-200 text-left"
          >
            Add Pickup
            <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
          </button>
        </div>
        <div className="text-left p-4 relative xl:w-1/2">
          <button
            type="button"
            // disabled={isSubmitting}
            onClick={nextButtonClick}
            className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                     text-sm font-bold transition duration-200 text-left"
          >
            {/* {buttonLabels[step - 1]} */}
            Select Delivery
            <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

AddPickup.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  addPickup: PropTypes.func.isRequired,
  updatePickup: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  serviceType: PropTypes.string.isRequired,
  nextClick: PropTypes.any.isRequired,
  showPickupForm: PropTypes.bool.isRequired,
  setShowPickupForm: PropTypes.func.isRequired,
  changedPickups: PropTypes.array.isRequired,
  setChangedPickups: PropTypes.func.isRequired
};

export default AddPickup;
