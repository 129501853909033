module.exports.LTL_FREIGHT = '5805d83787ae495159586545';
module.exports.BLANKET_WRAP = '5805d83787ae495159586546';
module.exports.TRUCKLOAD_FLAT = '5805d83787ae495159586547';
module.exports.TRUCKLOAD_VAN = '5805d83787ae495159586548';
module.exports.PARTIAL_FLAT = '5805d83787ae49515958654a';
module.exports.PARTIAL_VAN = '5805d83787ae495159586549';

module.exports.PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT = '57fb6370b13762ee6a3f94d0';
module.exports.PICKUP_LIFT_GATE = '57fb6392b13762ee6a3f94d1';
module.exports.PICKUP_LIMITED_ACCESS = '582ecfd41e8e062e7dc283e1';
module.exports.PICKUP_RESIDENTIAL = '57fb63b3b13762ee6a3f94d2';
module.exports.PICKUP_INSIDE_PICKUP = '57fb63ceb13762ee6a3f94d3';
module.exports.PICKUP_DROP_AT_TERMINAL = '57fb63e7b13762ee6a3f94d5';
module.exports.PICKUP_TRADE_SHOW_DROP = '57fb63f4b13762ee6a3f94d6';
module.exports.PICKUP_APPOINTMENT = '67a310350ad93c6c5746381f';

module.exports.DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT =
  '57fb6370b13762ee6a3f94d0';
module.exports.DELIVERY_LIFT_GATE = '57fb6392b13762ee6a3f94d1';
module.exports.DELIVERY_LIMITED_ACCESS = '582ece541e8e062e7dc283e0';
module.exports.DELIVERY_RESIDENTIAL = '57fb63b3b13762ee6a3f94d2';
module.exports.DELIVERY_INSIDE_PICKUP = '57fb63ceb13762ee6a3f94d3';
module.exports.DELIVERY_TERMINAL = '57fb63e7b13762ee6a3f94d5';
module.exports.DELIVERY_TRADE_SHOW = '57fb63f4b13762ee6a3f94d6';
module.exports.DELIVERY_APPOINTMENT = '67a310350ad93c6c57463820';
module.exports.DELIVERY_UNPACK = '67a310350ad93c6c57463821';
module.exports.DELIVERY_DEBRIS_REMOVAL_2 = '67a310350ad93c6c57463822';
module.exports.DELIVERY_EXTRA_MAN = '67a310350ad93c6c57463823';
