import axios from 'axios';

import { trackPromise } from 'react-promise-tracker';

import API_ENDPOINTS from '../constants/api-endpoints.constants';

const api = axios.create({
  baseURL: API_ENDPOINTS.baseURL
});

api.interceptors.request.use(
  (config) => {
    const options = { ...config };

    if (['/quote/downloadBOL', '/quote/downloadROC'].includes(config.url)) {
      options.responseType = 'blob';
    }

    if (config.url !== '/salesrepresentative') {
      options.params = {
        ...options.params,
        platform: 'web'
      };
    } else {
      options.params = {
        ...options.params
      };
    }

    return options;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      [401].indexOf(error.response.status) !== -1 &&
      error.response.request.responseURL.startsWith(API_ENDPOINTS.baseURL)
      // 403
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.clear();

      window.location.href = '/login';
      // } else if (error.response && error.response.status === 404) {
      //   console.log('it is 404', error.response);
      //   window.location.href = '/page-not-found';
      // } else if (error.response && error.response.status === 500) {
      //   window.location.href = '/something-went-wrong';
    }
    return Promise.reject(error);
  }
);

const get = (url, param) => trackPromise(api.get(url, param));

const post = (url, body, param) => trackPromise(api.post(url, body, param));

const put = (url, body, param) => trackPromise(api.put(url, body, param));

const del = (param) => trackPromise(api.delete(param));

export default {
  get,
  post,
  put,
  del
};
