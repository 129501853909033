import React, { forwardRef, useImperativeHandle, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { requestManagerApproval } from '../../services/salesrep.service';

const ReviewRequiredInformation = forwardRef((props, ref) => {
  const defaultInfo = {
    callerName: '',
    phoneNumber: '',
    emailProvided: '',
    comments: ''
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callerInfo, setCallerInfo] = useState(defaultInfo);

  // This function was called on CarrierDetailActions.jsx
  // eslint-disable-next-line no-unused-vars
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCallerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await requestManagerApproval({
        type: props.type,
        number: props.number,
        additionalDetails: callerInfo
      });
      setCallerInfo(defaultInfo);
      closeModal();

      return toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useImperativeHandle(ref, () => ({
    openModal
  }));

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Caller Information"
      style={customStyles}
    >
      <h2 className="flex justify-center text-xl font-bold">
        Caller Information
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="pr-1 mb-4">
          <label
            className="block w-full mb-2 font-bold text-black"
            for="callerName"
          >
            Who called you?
          </label>
          <input
            type="text"
            name="callerName"
            className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
            value={callerInfo.callerName}
            onChange={handleInputChange}
            id="callerName"
            required
          />
        </div>
        <div className="pr-1 mb-4">
          <label
            className="block w-full mb-2 font-bold text-black"
            for="phoneNumber"
          >
            What was the phone number they called from?
          </label>
          <input
            type="text"
            name="phoneNumber"
            className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
            value={callerInfo.phoneNumber}
            onChange={handleInputChange}
            id="phoneNumber"
            pattern="\+?[0-9\s\-\(\)]{7,15}"
            required
          />
        </div>
        <div className="pr-1 mb-4">
          <label className="block w-full mb-2 font-bold text-black" for="email">
            What email did they give you?
          </label>
          <input
            type="email"
            name="emailProvided"
            className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
            value={callerInfo.emailProvided}
            onChange={handleInputChange}
            id="email"
            required
          />
        </div>
        <div className="pr-1 mb-4">
          <label
            className="block w-full mb-2 font-bold text-black"
            for="comments"
          >
            Comments
          </label>
          <textarea
            name="comments"
            className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
            value={callerInfo.comments}
            onChange={handleInputChange}
            for="comments"
          />
        </div>
        <div className="flex flex-row gap-1">
          <button
            type="submit"
            className="flex flex-row items-center justify-between w-1/2 px-4 py-3 text-sm font-bold text-left text-white transition duration-200 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
          >
            Submit
          </button>
          <button
            type="button"
            className="flex flex-row items-center justify-between w-1/2 px-4 py-3 text-sm font-bold text-white transition duration-200 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
});

ReviewRequiredInformation.propTypes = {
  type: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired
};

export default ReviewRequiredInformation;
