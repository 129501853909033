import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import Pagination from '../Pagination';

import { getAllCarriers } from '../../services/salesrep.service';

import { ReactComponent as SearchIcon } from '../../assets/images/search_icon.svg';
import { UpdateDispatcherStatus } from '../../services/dispatcher.service';
import CarrierSearch from '../CarrierSearch';

const CarrierTableView = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [query, setQuery] = useState('');
  const [isAPICalled, setisAPICalled] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  async function fetchAllUsers() {
    try {
      const response = await getAllCarriers(
        recordsPerPage,
        currentPage,
        encodeURIComponent(query)
      );
      setCarriers(response.data.users);
      setTotalCount(response.data.totalUsers);
      setisAPICalled(true);
      setIsAdmin(response?.data?.isAdmin);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchAllUsers();
  }, [currentPage, query]);

  const search = (queryString) => {
    setQuery(queryString);
    setCurrentPage(1);
  };

  const onViewCarrier = (carrier) => {
    return carrier?.dot
      ? navigate(`/salesRep/carriers/dot/${carrier?.dotNumber}`)
      : navigate(`/salesRep/carriers/mc/${carrier?.mcNumber}`);
  };

  const changeCarrierStatus = async (carrier) => {
    try {
      const response = await UpdateDispatcherStatus(carrier?.dispatcherId, {
        status: !carrier?.dispatcher_status
      });
      if (response.status === 200) {
        toast.success('Carrier Details updated successfully!');
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    } finally {
      fetchAllUsers();
    }
  };

  return (
    <div className="w-full">
      <div className="inline-block w-full p-4 pl-0 mb-6 md:w-9/12">
        <h3 className="inline-block text-3xl font-bold text-navy-500 ">
          Registered Carriers
        </h3>
      </div>
      <div className="items-center inline-block w-full p-4 pr-0 mb-6 text-right md:w-3/12">
        <div className="relative text-gray-600">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <SearchIcon />
          </span>
          <input
            className="block w-full px-4 py-3 pl-10 mb-3 leading-tight border appearance-none border-navy-400 focus:outline-none"
            type="search"
            placeholder="Search ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                search(event.target.value);
              }
            }}
          />
        </div>
      </div>
      <div className="my-4">
        <h2 className="text-xl font-semibold">Preapprove Carrier</h2>
        <div className="flex items-center justify-between w-1/2 p-4 my-6 border border-gray-300">
          <CarrierSearch />
        </div>
      </div>

      <div className="inline-block w-full overflow-x-scroll">
        <h2 className="my-4 text-xl font-semibold">All Carriers</h2>
        <table className="w-full text-left table-auto">
          <thead className="mb-2 text-gray-400">
            <tr>
              <th className="pb-2 pl-3 font-light">Name</th>
              <th className="pb-2 pl-3 font-light">Contact Name</th>
              <th className="pb-2 pl-3 font-light">Email</th>
              <th className="pb-2 pl-3 font-light">#DOT</th>
              <th className="pb-2 pl-3 font-light">#MC</th>
              <th className="pb-2 pl-3 font-light">Radius</th>
              {isAdmin && <th className="pb-2 pl-3 font-light">Status</th>}
              <th className="pb-2 pl-3 font-light">Action</th>
            </tr>
          </thead>
          <tbody className="p-2 border border-gray-300">
            {isAPICalled ? (
              <>
                {carriers.map((carrier) => {
                  return (
                    <tr
                      key={carrier?.carrierId}
                      className="border-b border-gray-300"
                    >
                      <td className="py-4 pl-3">{carrier?.carrierName}</td>
                      <td className="py-4 pl-3">{carrier?.dispatcherName}</td>
                      <td className="py-4 pl-3">{carrier?.dispatcherEmail}</td>
                      <td className="py-4 pl-3">{carrier?.dotNumber}</td>
                      <td className="py-4 pl-3">{carrier?.mcNumber}</td>
                      <td className="py-4 pl-3">{carrier?.radius}</td>
                      {isAdmin && (
                        <td className="py-4 pl-3">
                          <div
                            className={`flex items-center justify-center rounded-4xl w-32 p-1 ${
                              carrier?.dispatcher_status
                                ? 'btn-container--on'
                                : 'btn-container--off'
                            }`}
                          >
                            <span className="ml-0 mr-1">
                              {carrier?.dispatcher_status
                                ? 'Active'
                                : 'Inactive'}
                            </span>
                            <div
                              className={`btn-switch ${
                                carrier?.dispatcher_status
                                  ? 'btn-switch--on'
                                  : ''
                              }`}
                              onClick={() => {
                                changeCarrierStatus(carrier);
                              }}
                              onKeyPress={() => {}}
                              role="button"
                              tabIndex={0}
                            >
                              <div
                                className={`btn-switch-circle ${
                                  carrier?.dispatcher_status
                                    ? 'btn-switch-circle--on'
                                    : ''
                                }`}
                                onClick={() => {
                                  changeCarrierStatus(carrier);
                                }}
                                onKeyPress={() => {}}
                                role="button"
                                tabIndex={0}
                              />
                            </div>
                          </div>
                        </td>
                      )}
                      <td className="py-4">
                        {(carrier?.dotNumber || carrier?.mcNumber) && (
                          <button
                            type="button"
                            onClick={() => {
                              onViewCarrier(carrier);
                            }}
                            className="relative px-4 py-2 mx-4 text-sm font-bold text-left text-white transition duration-200 border-2 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                          >
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}

                {!totalCount && (
                  <tr className="p-3 border-none">
                    <td colSpan="3">
                      <h3 className="p-3 text-center">No carriers found!</h3>
                    </td>
                  </tr>
                )}
              </>
            ) : (
              ''
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 0 && (
        <div className="flex items-end justify-end px-4 py-3 border-gray-200 sm:px-6">
          <Pagination
            activePage={currentPage}
            perPage={recordsPerPage}
            total={totalCount}
            handlePageChange={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </div>
  );
};

export default CarrierTableView;
