import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';
import toast from 'react-hot-toast';
import { trackingService } from '../../services/quote.service';
import {
  addNoteService,
  getAllInternalNotesService,
  getAllExternalNotesService
} from '../../services/notes.service';
import { TRACK_STATUS } from '../../constants/quote.constants';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/right_arrow.svg';
import { useAuthContext } from '../../contexts/auth.context';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../constants/service_type.constants';
import { additionalCharges } from '../../utils/quote.util';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';

const TrackingPage = () => {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const { authUser } = useAuthContext();

  const [quote, setQuote] = useState(null);
  const [pickupDate, setPickupDate] = useState('');
  const [pickupData, setPickupData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [navStatus, setNavStatus] = useState('details');
  const [internalNotes, setInternalNotes] = useState([]);
  const [externalNotes, setExternalNotes] = useState([]);
  const [internalNoteText, setInternalNoteText] = useState('');
  const [externalNoteText, setExternalNoteText] = useState('');
  const [loadType, setLoadType] = useState('');
  const [trackingInfo, setTrackingInfo] = useState('');

  const { FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const { DELIVERED_QUOTE } = BID_STATUS;

  async function fetchQuoteDetails() {
    const trackingResponse = await trackingService(quoteId);
    const quoteData = trackingResponse?.data?.quote;

    setQuote(quoteData);
    if (quoteData.service_type === LTL_FREIGHT) {
      setPickupDate(
        quoteData?.carrierPickupDate ||
          quoteData?.tracking_details?.pickedAt ||
          quoteData[quoteData.carrier_type]?.pickupDate
      );
    } else {
      setPickupDate(
        quoteData?.carrierPickupDate ||
          quoteData?.tracking_details?.pickupDate ||
          quoteData?.pickup_date ||
          ''
      );
    }

    setPickupData(
      quoteData.isMultipleQuote ? quoteData.pickupInfo : [quoteData]
    );
    setDeliveryData(
      quoteData.isMultipleQuote ? quoteData.deliveryInfo : [quoteData]
    );
  }

  async function getInternalNotes() {
    const internalNotesResponse = await getAllInternalNotesService(quoteId);
    const internalNotesData = internalNotesResponse?.data?.internalNotes;
    setInternalNotes(internalNotesData?.reverse());
  }

  async function getExternalNotes() {
    const externalNotesResponse = await getAllExternalNotesService(quoteId);
    const externalNotesData = externalNotesResponse?.data?.externalNotes;
    setExternalNotes(externalNotesData?.reverse());
  }

  const addInternalNote = async () => {
    const body = {
      note: internalNoteText,
      type: 1,
      quoteId
    };
    try {
      if (!internalNoteText) {
        toast.error('Enter Internal Note');
      } else {
        const response = await addNoteService(body);
        if (response.data.flag) {
          toast.success(response.data.message);
          setInternalNoteText('');
          getInternalNotes();
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const addExternalNote = async () => {
    const body = {
      note: externalNoteText,
      type: 2,
      quoteId
    };
    try {
      if (!externalNoteText) {
        toast.error('Enter External Note');
      } else {
        const response = await addNoteService(body);
        if (response.data.flag) {
          toast.success(response.data.message);
          setExternalNoteText('');
          getExternalNotes();
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchQuoteDetails();
    getInternalNotes();
    getExternalNotes();
  }, []);

  useEffect(() => {
    if (quote?.loadType) {
      setLoadType(quote?.loadType);
    }

    if (quote?.service_type) {
      if (quote?.service_type === BLANKET_WRAP) {
        if (quote?.tracking_details?.trackingNumber) {
          setTrackingInfo(`Pro# ${quote?.tracking_details?.trackingNumber}`);
        } else {
          setTrackingInfo('');
        }
      } else {
        setTrackingInfo(
          `${quote?.carrier_type?.toUpperCase()} Pro# ${
            quote?.tracking_details?.trackingNumber
          }`
        );
      }
    }
  }, [quote]);

  useEffect(() => {}, [navStatus]);
  const onBackArrowClick = () => {
    navigate(-1);
  };
  const onTrackAnotherClick = () => {
    navigate(
      ['salesRep', 'customerRep'].includes(authUser?.user?.usertype)
        ? '/salesRep/enroute-quotes'
        : '/shipper-enroute',
      { replace: true }
    );
  };

  return (
    <section className="py-10">
      <div className="container mx-auto">
        <div className="mb-8 w-full px-3 min-h-full">
          <div className="mb-6 relative text-navy-500">
            <div
              tabIndex="-1"
              role="button"
              onKeyPress={() => {}}
              onClick={onBackArrowClick}
              className="flex items-center"
            >
              <LeftArrowIcon className="w-6 h-6 flex items-center" />
              <h3 className="text-2xl ml-4 font-bold flex items-center">
                Shipment Tracking
              </h3>
            </div>
          </div>
          {quote ? (
            <div className="text-black md:flex flex-wrap">
              <div className="bg-gray-100 h-full border-2 w-full md:w-1/2 inline-block tracking-wide">
                <div className="w-full m-2 space-y-2">
                  <p>
                    {`NET#
                      ${quote?.internalTrackingNumber}`}
                  </p>
                  {['salesRep', 'customerRep'].includes(
                    authUser?.user?.usertype
                  ) &&
                  [LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
                  ![
                    'salesRep-pending-quotes',
                    'shipper-pending-loads'
                  ].includes(loadType) ? (
                    <p>{trackingInfo}</p>
                  ) : null}
                </div>
                <hr />
                <div className="w-full p-4 mb-4">
                  <p className="text-center text-xl font-bold text-navy-500">
                    {
                      TRACK_STATUS[
                        quote?.tracking_details?.shipmentStatus?.statusCd
                      ]
                    }
                  </p>
                  <div className="p-10">
                    <div className="flex items-center">
                      {Object.keys(TRACK_STATUS).map((step, index) => {
                        const showStatus =
                          Number(step) ===
                            quote?.tracking_details?.shipmentStatus?.statusCd &&
                          ![
                            'salesRep-pending-quotes',
                            'shipper-pending-loads'
                          ].includes(loadType);

                        return (
                          <>
                            <div className="flex items-center text-black relative">
                              <span className="flex h-4 w-4">
                                {showStatus ? (
                                  <>
                                    {' '}
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-navy-500" />
                                    <span className="relative inline-flex rounded-full h-4 w-4 border-2 bg-navy-500 border-navy-500" />
                                  </>
                                ) : (
                                  <span className="relative inline-flex rounded-full h-4 w-4 border-2 border-navy-500" />
                                )}
                              </span>
                              <div className="absolute top-0 -ml-14 text-center mt-6 w-32 text-sm whitespace-normal font-medium">
                                {Number(step) !== 2
                                  ? TRACK_STATUS[step]
                                  : `${TRACK_STATUS[step]} ${pickupDate}`}
                                {Number(step) === 5 &&
                                quote.bidStatus === DELIVERED_QUOTE ? (
                                  <span className="block">
                                    {quote?.tracking_details?.deliveredAt ??
                                      moment(quote?.deliveryDateTime).format(
                                        'MM/DD/YYYY'
                                      )}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            {index !== 4 ? (
                              <div className="flex-auto border-t-2 border-navy-500 opacity-70" />
                            ) : (
                              ''
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between py-4 mx-4 text-center">
                  <div className="">
                    {quote.bidStatus === DELIVERED_QUOTE ? (
                      <>
                        <p>Delivered At:</p>
                        <p className="font-medium">
                          {quote?.tracking_details?.deliveredAt ??
                            moment(quote?.deliveryDateTime).format(
                              'MM/DD/YYYY'
                            )}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>Delivery ETA:</p>
                        <p className="font-medium">
                          {quote?.tracking_details?.estimatedDelivery ??
                            quote[quote.carrier_type]?.transitDate}
                        </p>
                      </>
                    )}
                  </div>
                  {quote.bidStatus === DELIVERED_QUOTE ? (
                    <div>
                      <p>Delivered By:</p>
                      <p className="font-medium">
                        {quote?.tracking_details?.driverName ||
                          quote.driverName}
                      </p>
                    </div>
                  ) : null}
                  <div>
                    <p>Last Updated:</p>
                    <p className="font-medium">
                      {moment(quote.updatedAt).format('MM/DD/YYYY h:mm A')}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="p-4 mx-8 text-center">
                  <p>{quote?.tracking_details?.shipmentStatus?.description}</p>
                </div>
                <button
                  type="button"
                  onClick={() => onTrackAnotherClick()}
                  className="py-2 bg-navy-500 w-full text-white font-medium flex justify-between"
                >
                  <span className="mx-4">Track another shipment</span>
                  <span className="mx-4">
                    <RightArrowIcon className="w-6 h-6 flex items-center" />
                  </span>
                </button>
              </div>
              <div className="bg-gray-100 border-2 w-full md:w-1/2 inline-block">
                <div className="w-full m-2">
                  <p>Additional Information</p>
                </div>
                <hr />
                <div className="w-full">
                  <nav>
                    <div className="max-w-6xl mx-auto">
                      <div className="flex justify-between">
                        <div className="items-center space-x-4 text-navy-500 text-sm inline-flex">
                          <button
                            type="button"
                            onClick={() => setNavStatus('details')}
                            className={`p-2 text-navy-500 border-navy-500 ${
                              navStatus === 'details' ? 'border-b-2' : ''
                            }`}
                          >
                            Details
                          </button>
                          <button
                            type="button"
                            onClick={() => setNavStatus('history')}
                            className={`p-2 text-navy-500 border-navy-500 ${
                              navStatus === 'history' ? 'border-b-2' : ''
                            }`}
                          >
                            History
                          </button>

                          {['salesRep', 'customerRep'].includes(
                            authUser?.user?.usertype
                          ) && (
                            <button
                              type="button"
                              onClick={() => setNavStatus('internal_notes')}
                              className={`p-2 text-navy-500 border-navy-500 ${
                                navStatus === 'internal_notes'
                                  ? 'border-b-2'
                                  : ''
                              }`}
                            >
                              Internal Notes
                            </button>
                          )}

                          <button
                            type="button"
                            onClick={() => setNavStatus('external_notes')}
                            className={`p-2 text-navy-500 border-navy-500 ${
                              navStatus === 'external_notes' ? 'border-b-2' : ''
                            }`}
                          >
                            {['salesRep', 'customerRep'].includes(
                              authUser?.user?.usertype
                            )
                              ? 'External Notes'
                              : 'Notes'}
                          </button>
                          {/* <p className="p-2 hover:text-navy-500 transition duration-300">
                              Times displayed in timezone of event.
                            </p> */}
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
                <hr />
                {navStatus === 'details' && (
                  <div className="w-full py-2 h-96 overflow-scroll">
                    <div className="m-2 py-2 text-sm">
                      <p className="mb-2 font-medium">Shipper</p>
                      {pickupData?.map((pick) => {
                        return quote?.bidType === OPEN_BID ? (
                          <div className="my-2">
                            <p>{pick?.pickup_zip_code}</p>
                          </div>
                        ) : (
                          <div className="my-2">
                            <p>{pick?.pickup_address?.company}</p>
                            <p>{pick?.pickup_address?.address1}</p>
                            <p>{`${pick?.pickup_address?.city}, ${pick?.pickup_address?.state} ${pick?.pickup_zip_code}`}</p>
                            <p>{pick?.pickup_address?.contactnumber}</p>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                    <div className="m-2 py-2 text-sm">
                      <p className="mb-2 font-medium">Consignee</p>
                      {deliveryData?.map((del) => {
                        return quote?.bidType === OPEN_BID ? (
                          <div className="my-2">
                            <p>{del?.delivery_zip_code}</p>
                          </div>
                        ) : (
                          <div className="my-2">
                            <p>{del?.delivery_address?.company}</p>
                            <p>{del?.delivery_address?.address1}</p>
                            <p>{`${del?.delivery_address?.city}, ${del?.delivery_address?.state} ${del?.delivery_zip_code}`}</p>
                            <p>{del?.delivery_address?.contactnumber}</p>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                    {quote?.bidType === OPEN_BID &&
                    [
                      'salesRep-pending-quotes',
                      'shipper-pending-loads'
                    ].includes(loadType) ? null : (
                      <div className="m-2 py-2 text-sm">
                        <p className="mb-2 font-medium">Shipment Details</p>
                        {quote?.cargos?.map((cargo) => {
                          return (
                            <p>{`${cargo.quantity} Pc/s weighing ${cargo.weight} Lbs`}</p>
                          );
                        })}
                        <p>{`Total Charge Amount: USD ${Number(
                          ([FIXED_AMOUNT, OPEN_BID].includes(quote.bidType)
                            ? quote?.amount || 0
                            : quote?.estimate || 0) +
                            (additionalCharges(quote).revenueCharge || 0)
                        ).toFixed(2)}`}</p>
                        <p>{`Pickup Date: ${pickupDate}`}</p>
                      </div>
                    )}
                  </div>
                )}

                {navStatus === 'internal_notes' && (
                  <div className="p-2">
                    <div className="flex w-full">
                      <div className="w-1/2 md:w-2/3 float-left pr-3">
                        <input
                          type="text"
                          name="internalNoteInput"
                          className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                          placeholder="Type internal note here..."
                          value={internalNoteText}
                          onChange={(e) => {
                            setInternalNoteText(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-1/2 md:w-1/3 float-right">
                        <button
                          className="bg-navy-500 w-full text-white font-medium py-2"
                          type="submit"
                          onClick={() => addInternalNote()}
                        >
                          Add Internal Note
                        </button>
                      </div>
                    </div>
                    <div className="max-h-72 overflow-scroll">
                      {internalNotes?.map((internalNote) => {
                        return (
                          <>
                            <div className="p-2">
                              <p>{`${moment(internalNote.createdAt).format(
                                'MM/DD/YYYY hh:mm A'
                              )}`}</p>
                              <p className="text-lg font-medium">
                                {internalNote.note}
                              </p>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}

                {navStatus === 'external_notes' && (
                  <div className="p-2">
                    {['salesRep', 'customerRep'].includes(
                      authUser?.user?.usertype
                    ) && (
                      <div className="flex w-full">
                        <div className="w-1/2 md:w-2/3 float-left pr-3">
                          <input
                            type="text"
                            name="externalNoteInput"
                            className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                            placeholder="Type external note here..."
                            value={externalNoteText}
                            onChange={(e) => {
                              setExternalNoteText(e.target.value);
                            }}
                          />
                        </div>
                        <div className="w-1/2 md:w-1/3 float-right">
                          <button
                            className="bg-navy-500 w-full text-white font-medium py-2"
                            type="submit"
                            onClick={() => addExternalNote()}
                          >
                            Add External Note
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="max-h-72 overflow-scroll">
                      {externalNotes?.map((externalNote) => {
                        return (
                          <>
                            <div className="p-2">
                              <p>{`${moment(externalNote.createdAt).format(
                                'MM/DD/YYYY hh:mm A'
                              )}`}</p>
                              <p className="text-lg font-medium">
                                {externalNote.note}
                              </p>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}

                {navStatus === 'history' && (
                  <div className="py-2">
                    {quote.carrier_type !== 'abf'
                      ? quote?.tracking_details?.cities?.map((city) => {
                          return (
                            <>
                              <div className="m-2">
                                <p>{`${city.traceAt} (Central Standard Time)`}</p>
                                <p className="text-lg font-medium">{`${city.cityName}, ${city.stateCd}`}</p>
                              </div>
                              <hr />
                            </>
                          );
                        })
                      : quote?.tracking_details?.abfTrackingHistory
                          ?.slice()
                          ?.reverse()
                          ?.map((item) => {
                            return (
                              <>
                                <div className="m-2">
                                  <p>{`${item.traceAt} (Central Standard Time)`}</p>
                                  <p className="text-lg font-medium">{`${item.message}`}</p>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                  </div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default TrackingPage;
