import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const getCarrierBidsService = () => {
  return API.get(API_ENDPOINTS.BID.GetCarrierBids, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([2]),
      isPickupByCarrier: false
    }
  });
};

const getPendingCarrierBidsService = () => {
  return API.get(API_ENDPOINTS.BID.GetCarrierBids, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([1]),
      isPickupByCarrier: false
    }
  });
};

const getRejectedCarrierBidsService = () => {
  return API.post(
    API_ENDPOINTS.BID.GetCarrierBids,
    {},
    {
      params: {
        token: localStorage.getItem('token'),
        status: JSON.stringify([4, 5]),
        isPickupByCarrier: false
      }
    }
  );
};

const getAssignedCarrierBidsService = () => {
  return API.get(API_ENDPOINTS.BID.GetCarrierBids, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([6]),
      isPickupByCarrier: false
    }
  });
};

const getEnrouteCarrierBidsService = () => {
  return API.get(API_ENDPOINTS.BID.GetCarrierBids, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([6]),
      isPickupByCarrier: true
    }
  });
};

const getCompletedCarrierBidsService = () => {
  return API.get(API_ENDPOINTS.BID.GetCarrierBids, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([3]),
      isPickupByCarrier: true
    }
  });
};

const getBidDetailsService = (bidId) => {
  return API.post(
    `${API_ENDPOINTS.BID.GetBid}/${bidId}`,
    {},
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const getQuoteBidsService = (quoteId) => {
  return API.post(
    API_ENDPOINTS.BID.GetQuoteBids,
    {},
    {
      params: {
        token: localStorage.getItem('token'),
        quoteId
      }
    }
  );
};

const carrierSignatureService = (formData) => {
  return API.post(API_ENDPOINTS.BID.AddCarrierSignature, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const acceptBidService = (body) => {
  return API.post(API_ENDPOINTS.BID.AcceptBid, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const declineBidService = (body) => {
  return API.post(API_ENDPOINTS.BID.DeclineBid, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

export {
  getCarrierBidsService,
  getPendingCarrierBidsService,
  getRejectedCarrierBidsService,
  getAssignedCarrierBidsService,
  getEnrouteCarrierBidsService,
  getCompletedCarrierBidsService,
  getBidDetailsService,
  getQuoteBidsService,
  acceptBidService,
  declineBidService,
  carrierSignatureService
};
