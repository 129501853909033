import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import NETLogo from '../../assets/images/Nationwide-Logo.svg';
// import NETFooterLogo from '../../assets/images/Screen-Shot-2021-08-12-at-7-36-31-PM.png';

const Header = () => {
  const { pathname } = useLocation();

  return (
    <section>
      <nav className="relative py-6 bg-navy-500">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <Link className="text-3xl font-bold leading-none bg-white" to="/">
            {/* <img src={NETFooterLogo} alt="" width="150" /> */}
            <img src={NETLogo} alt="" width="150" />
          </Link>

          <div className="flex justify-end">
            <Link
              to="/sign-in"
              className={` ${
                pathname === '/sign-in'
                  ? 'bg-navy-200 hover:bg-navy-400 border border-white'
                  : 'bg-navy-500 hover:bg-white hover:text-navy-500 border border-navy-500'
              }  md:block md:ml-auto md:mr-3 py-2 px-2 text-sm text-white font-bold  transition duration-200`}
            >
              Sales Rep/ Customer Representative Login
            </Link>
            <Link
              to="/login"
              className={`${
                pathname === '/login'
                  ? 'bg-navy-200 hover:bg-navy-400 border border-white'
                  : 'bg-navy-500 hover:bg-white hover:text-navy-500 border border-navy-500'
              }  md:block md:ml-auto md:mr-3 py-2 px-2 text-sm text-white font-bold  transition duration-200`}
            >
              Carrier/Shipper Login
            </Link>
            <Link
              to="/sign-up"
              className={`${
                [
                  '/sign-up',
                  '/carrier-signup',
                  '/carrier-signup-dot',
                  '/shipper-signup'
                ].includes(pathname)
                  ? 'bg-navy-200 hover:bg-navy-400 border border-white'
                  : 'bg-navy-500 hover:bg-white hover:text-navy-500 border border-navy-500'
              }  lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 text-sm text-white font-bold  transition duration-200`}
            >
              Carrier/Shipper Signup
            </Link>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Header;
