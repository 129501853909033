import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import BasicDetailsForm from './BasicDetailsForm';
import AddressDetailsForm from './AddressDetailsForm';
import ContactDetailsForm from './ContactDetailsForm';
import {
  carrierSignUpService,
  getCarrierMCPDataService
} from '../../../services/carrier.service';

import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';

const GetStepContent = (step, prevStep, formContent) => {
  switch (step) {
    case 1:
      return <BasicDetailsForm {...formContent} />;

    case 2:
      return <AddressDetailsForm prevStep={prevStep} {...formContent} />;

    case 3:
      return <ContactDetailsForm prevStep={prevStep} {...formContent} />;

    default:
      return null;
  }
};

const CarrierSignupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { dot, email, invitedBy, exists } = queryString.parse(location.search);
  const [step, setStep] = useState(1);
  const [compiledForm, setCompiledForm] = useState({});
  const [isAcceptable, setIsAcceptable] = useState(false);

  const buttonLabels =
    exists === 'true'
      ? ['Signup']
      : ['Address', 'Contact Details', 'Request Access'];

  const validationSchema = [
    // validation for step1
    yup.object({
      company: yup.string().required('Carrier name is required'),
      name: yup.string().required('Contact name is required'),
      email: yup
        .string()
        .required('Email is required!')
        .email('Please enter a valid email.'),
      password: yup.string().required('Password is required')
    }),
    // validation for step2
    yup.object({
      address1: yup.string().required('Address Line 1 is required'),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      country: yup.string().required('Country is required').default('US'),
      zip: yup.string().required('Zip code is required')
    }),
    // validation for step3
    yup.object({
      contactnumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Mobile number must be only digits')
        .required('Mobile number is required')
        .max(11)
        .min(10, 'Mobile number must be at least 10 digits'),
      fax: yup
        .string()
        .matches(/^[0-9]+$/, 'Fax number must be only digits')
        .required('Fax number is required'),
      dot: yup
        .string()
        .matches(/^[0-9]+$/, 'DOT number must be only digits')
        .required('DOT number is required'),
      radius: yup.mixed().required('Area of operation is required'),
      equipmentType: yup
        .array()
        .required('Equipment types are required')
        .min(1, 'Equipment types are required'),
      invitedBy: yup.string().optional()
    })
  ];

  const currentValidationSchema = validationSchema[step - 1];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      canDriverBook: 'no'
    }
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid, isSubmitting },
    reset,
    trigger
  } = methods;
  const form = watch();

  const onSubmitUser = async () => {
    try {
      const body =
        exists === 'true'
          ? {
              name: form.name,
              email: form.email,
              password: form.password,
              dot: form.dot,
              invitedBy
            }
          : {
              company: form.company,
              name: form.name,
              email: form.email,
              password: form.password,
              address: {
                address1: form.address1,
                address2: form.address2,
                city: form.city,
                state: form.state,
                country: form.country || 'US',
                zip: form.zip,
                contactnumber: form.contactnumber
              },
              fax: form.fax,
              dot: form.dot,
              radius: form?.radius?.value,
              equipmentType: form.equipmentType.map(
                (equipment) => equipment?.value
              ),
              invitedBy
            };

      const response = await carrierSignUpService({
        ...body,
        version: process.env.REACT_APP_APP_VERSION
      });
      if (response.data.flag) {
        toast.success(response.data.message);
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
      switch (step) {
        case 2:
          setCompiledForm({ ...compiledForm, two: form });
          break;

        case 3:
          setCompiledForm({ ...compiledForm, three: form });
          break;

        default:
          break;
      }
    }
  };

  const nextStep = async () => {
    let isStepValid;

    switch (step) {
      case 1:
        isStepValid = await trigger(['company', 'name', 'email', 'password']);
        break;

      case 2:
        isStepValid = await trigger([
          'address1',
          'address2',
          'city',
          'state',
          'country',
          'zip'
        ]);
        break;

      case 3:
        isStepValid = await trigger([
          'contactnumber',
          'fax',
          'dot',
          'radius',
          'equipmentType'
        ]);
        break;

      default:
        break;
    }

    if (isStepValid || isValid) {
      switch (step) {
        case 1:
          setCompiledForm({ ...compiledForm, one: form });
          if (exists === 'true') {
            return;
          }
          setStep((prev) => prev + 1);
          break;

        case 2:
          setCompiledForm({ ...compiledForm, two: form });
          setStep((prev) => prev + 1);
          break;

        case 3:
          setCompiledForm({ ...compiledForm, three: form });
          break;

        default:
          break;
      }
    }
  };

  const memoizedStepContent = useMemo(
    () => GetStepContent(step, prevStep, compiledForm),
    [compiledForm, step, prevStep]
  );

  const fetchData = async () => {
    try {
      const response = await getCarrierMCPDataService({
        type: 'dot',
        number: dot
      });

      const mcpData = response?.data?.data?.mcpData;

      if (mcpData) {
        if (mcpData.isBlocked || mcpData.riskAssesment === 'UnacceptableFail') {
          toast.error(
            'You cannot create an account as this carrier is currently disabled in our system.'
          );
          setIsAcceptable(false);
          return;
        }
        setIsAcceptable(true);

        reset({
          company: mcpData.companyName,
          name: mcpData.contact,
          email: email || mcpData.email,
          address1: mcpData?.address?.address1,
          address2: mcpData?.address?.address2 || '',
          city: mcpData?.address?.city,
          state: mcpData?.address?.state,
          zip: mcpData?.address?.zip,
          contactnumber: mcpData?.phone?.replace(/\D/g, ''),
          fax: mcpData?.fax?.replace(/\D/g, ''),
          dot: mcpData?.dot
        });
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
      navigate('/carrier-signup-dot');
    }
  };

  useEffect(() => {
    if (dot) {
      fetchData();
    }
  }, [dot]);

  return (
    <section className="py-10 lg:py-20">
      <div className="container mx-auto">
        <div className=" mx-auto">
          <div className="mb-6 lg:mb-10 text-left relative">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmitUser)}>
                {memoizedStepContent}
                {step === buttonLabels.length ? (
                  <div className="text-left p-4 relative">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isAcceptable}
                      onClick={nextStep}
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                     text-sm font-bold transition duration-200 text-left"
                    >
                      {buttonLabels[step - 1]}
                      <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
                    </button>
                  </div>
                ) : (
                  <div className="text-left p-4 relative">
                    <button
                      disabled={!isAcceptable}
                      type="button"
                      onClick={nextStep}
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                      hover:border-navy-600 active:border-navy-700  text-sm font-bold transition duration-200 text-left"
                    >
                      {buttonLabels[step - 1]}
                      <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
                    </button>
                  </div>
                )}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarrierSignupPage;
